import { List } from "antd";
import React, { useEffect, useState } from "react";
import ItemSurvey from "./ItemSurvey";
import { AuditOutlined, ClockCircleFilled,  DashboardOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

const ViewSurveyResult = ({ dataQues, dataAnswer, dataScore, time, dataResponse }) => {
    const [dataQ, setDataQ] = useState([]);
    useEffect(() => {
        setDataQ([...dataQues]);
    }, [dataQues])
    return (
        <div>
            <p><ClockCircleFilled /> Thời gian: {time ? dayjs(time).format("HH:mm DD/MM/YYYY") : ''}</p>
            <p><DashboardOutlined /> Số điểm: <strong>{dataScore?.total_score ?? ''}</strong></p>
            <p><AuditOutlined /> Kết luận: <strong>{dataScore?.conclude ?? ''}</strong></p>
            <List
                header={<p style={{fontWeight: '600'}}>Chi tiết bài khảo sát</p>}
                bordered
                dataSource={dataQ}
                renderItem={(item, index) => (<ItemSurvey dataAnswer={dataAnswer} dataQues={item} index={index} value={dataResponse.filter(item => item.question_id === dataQ?.[index]?.id)?.[0]?.answer_id}/>)}
                style={{marginTop: 20, marginBottom: 10, paddingBottom: 15}}
            />
        </div>
    )
}
export default ViewSurveyResult;