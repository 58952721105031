import { Button, Checkbox, Col, Divider, Form, Image, Input, Modal, Row, Select, Space, Table, message, theme } from "antd";
import React, { useEffect, useState } from "react";
import { consoleLogUtil } from "../../../utils/consoleLogUtil";
import { BASE_URL } from "../../../settings/config";
import { addQuestionForSurvey, getDetailSurveyById, getListQuestion, getListQuestionType, getQuestionForSurvey } from "../../../services/apis";
import LoadingBase from "../../../components/LoadingBase";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const AddQuesToSurveyPage = () => {
    const { token } = theme.useToken();
    const userReducer = useSelector(state => state?.userReducer);
    const [form] = Form.useForm();
    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);
    const [modalLoading, setModalLoading] = useState(false);
    const [lstQuestion, setLstQuestion] = useState([]);
    const [isShowModal, setIsShowModal] = useState(false);
    const [dataModal, setDataModal] = useState([]);
    const [lstTypeQuestion, setLstTypeQuestion] = useState([]);
    const [selectedRow, setSelectedRow] = useState([]);
    const columns = [
        {
            title: 'Mã',
            dataIndex: 'id',
            key: 'id',
            width: 60,
            align: 'center',
        },
        {
            title: 'Nội dung câu hỏi',
            dataIndex: 'question_name',
            key: 'id',
            render: (text) => <div style={{ padding: 8 }}>{text}</div>
        },
        {
            title: 'Nhóm',
            dataIndex: 'category_name',
            key: 'id',
            render: (text) => <div style={{ padding: 8 }}>{text}</div>
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            key: 'id',
            render: (text) => <div style={{ padding: 8, color: text === "NEW" ? 'red' : 'blue' }}>{text === "NEW" ? "Mới" : "Đã lưu"}</div>
        },
        {
            title: 'Hành động',
            key: 'action',
            width: 80,
            align: 'center',
            render: (text, record) => <a onClick={() => handleDelete(record)} style={{}}>Xoá</a>
        },

    ];
    const columnsModal = [
        {
            title: 'Mã',
            dataIndex: 'id',
            key: 'id',
            width: 60,
            align: 'center',
        },
        {
            title: 'Nội dung câu hỏi',
            dataIndex: 'question_name',
            key: 'id',
            render: (text) => <div style={{ padding: 8, wordWrap: 'break-word',
                whiteSpace: 'normal'}}>{text}</div>
        },
        {
            title: 'Nhóm',
            dataIndex: 'category_name',
            key: 'id',
            render: (text) => <div style={{ padding: 8 }}>{text}</div>
        },
        {
            title: 'Đáp án riêng',
            dataIndex: 'isHaveAnswer',
            align: 'center',
            render: (text) => <div style={{ padding: 8 }}><Checkbox checked={text === "1" ? true : false} disabled /></div>
        },

    ];
    
    const formStyle = {
        maxWidth: 'none',
        background: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        padding: 24,
    };
    const { id } = useParams();

    const handleDelete = (data) => {
        const checkIndex = lstQuestion.findIndex(item => item.id === data.id);
        if (checkIndex > -1) {
            const temp = [...lstQuestion];
            temp.splice(checkIndex, 1);
            setLstQuestion([...temp]);
        }
    }
    const getDetailSurvey = async (id) => {
        const params = {
            id: id
        };
        const res = await getDetailSurveyById(params);
        if (res && res?.status === 1) {
            setData(res?.data);
        } else {
            message.error("Lấy dữ liệu thất bại. Vui lòng tải lại");
            setData();
        }
    }
    const getDataQuestion = async () => {
        const params = {
            keyword: form.getFieldValue("keyword") ?? '',
            category_id: form.getFieldValue("category_id") ?? '',
            limit: 200,
            currentPage: 1,
            isHaveAnswer: data?.type === "2" ? true : false
        }
        setModalLoading(true);
        const res = await getListQuestion(params);
        setModalLoading(false);
        if (res && res.status === 1) {
            setDataModal([...res?.data?.data]);
        } else {
            setDataModal([]);
            message.error(res?.msg ?? "Lấy dữ liệu thất bại");
        }
    }
    const getDataQuestionType = async () => {
        const params = {
            keyword: '',
            limit: 50,
            currentPage: 1
        }
        const res = await getListQuestionType(params);
        if (res && res.status === 1) {
            setLstTypeQuestion([...res?.data?.data]);
        } else {
            setLstTypeQuestion([]);
            message.error(res?.msg ?? "Lấy dữ liệu thất bại");
        }
    }
    const getQuesForSurvey = async (id) => {
        setLoading(true);
        const params = {
            survey_id: id
        };
        const res = await getQuestionForSurvey(params);
        setLoading(false);
        if (res && res?.status === 1) {
            setLstQuestion([...res?.data]);
        } else {
            setLstQuestion([]);
            message.error(res?.msg ?? 'Lấy dữ liệu câu hỏi thất bại');
        }
    }
    const handleShowModal = () => {
        setIsShowModal(true);
    }
    const onFinish = async (data) => {
        getDataQuestion();
    };
    const handleSaveData = async () => {
        if (lstQuestion.length === 0) {
            message.warning("Vui lòng thêm câu hỏi trước khi lưu");
            return;
        }
        const params = {
            survey_id: data?.id,
            create_by: userReducer?.data?.info?.id,
            question_ids: lstQuestion.map(item => item.id)
        }
        setLoading(true);
        const res = await addQuestionForSurvey(params);
        setLoading(false);
        if (res && res?.status === 1) {
            message.success("Lưu dữ liệu thành công");
            await getQuesForSurvey(data?.id);
        } else {
            message.error(res?.msg ?? "Lưu dữ liệu thất bại. Vui lòng thử lại");
        }
    }
    const handleAddQuestion = () => {
        if (selectedRow.length === 0) {
            message.warning("Vui lòng chọn câu hỏi");
            return;
        }
        let newArray = lstQuestion.concat(selectedRow.filter(row => !lstQuestion.find(ques => ques.id === row.id)).map(item => ({
            ...item,
            status: 'NEW'
        })));
        setLstQuestion([...newArray]);
        setIsShowModal(false);
    }
    const filterOption = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
    useEffect(() => {
        if (id && id > 0) {
            setLoading(true);
            Promise.all([getDetailSurvey(id), getDataQuestionType(), getQuesForSurvey(id)])
                .then(res => {})
                .catch(err => consoleLogUtil("err", err))
                .finally(() => setLoading(false));
        }
    }, [id])
    return (
        <div className='container-content'>
            <Row gutter={24}>
                <Col span={4}>
                    <Image src={BASE_URL + data?.imgUrl} style={{ width: '100%', height: '100%' }} />
                </Col>
                <Col span={18}>
                    <p style={{ fontWeight: '700', fontSize: 18 }}>{data?.survey_name}</p>
                    <p>{data?.description?.substring(0, 100)}</p>
                    <p style={{ fontWeight: '600', fontSize: 16, color: 'red' }}>Tổng số câu hỏi: {lstQuestion.length}</p>
                    <Space style={{ marginTop: 12 }}>
                        <Button type="primary" style={{ backgroundColor: 'orange' }} onClick={handleShowModal}>Thêm câu hỏi</Button>
                        <Button type="primary" onClick={handleSaveData}>Cập nhật dữ liệu</Button>
                    </Space>
                </Col>
            </Row>
            <Divider />
            <Table
                className='table-book'

                columns={columns}
                dataSource={lstQuestion}
                pagination={false}
                rowKey={(record) => record?.id}
                scroll={{ x: true }}
            />
            <Modal
                open={isShowModal}
                width={"80%"}
                okText={"Thêm câu hỏi"}
                cancelText="Thoát"
                onOk={() => handleAddQuestion()}
                onCancel={() => setIsShowModal(false)}
            >
                <Form form={form} name="advanced_search" style={formStyle} onFinish={onFinish}>
                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item
                                name={'keyword'}
                            >
                                <Input placeholder={'Nội dung câu hỏi'} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name={'category_id'}
                            >
                                <Select
                                    showSearch
                                    placeholder={"Chọn loại câu hỏi"}
                                    style={{ height: 40 }}
                                    defaultActiveFirstOption={false}
                                    filterOption={filterOption}
                                    notFoundContent={null}
                                    options={(lstTypeQuestion || []).map((d) => ({
                                        value: d.id,
                                        label: d.name,
                                    }))}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={4}>
                            <Space size="small">
                                <Button type="primary" htmlType="submit">
                                    Tìm kiếm
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>
                <Table
                    className='table-book'
                    rowSelection={{
                        type: 'checkbox',
                        onChange: (selectedKeys, selectedRows) => {
                            consoleLogUtil("selectedRows",selectedRows)
                            setSelectedRow([...selectedRows]);
                        },
                    }}
                    columns={columnsModal}
                    dataSource={dataModal}
                    pagination={{
                        pageSize: 50
                    }}
                    rowKey={(record) => record?.id}
                />
                <LoadingBase loading={modalLoading} />
            </Modal>
            <LoadingBase loading={loading} />
        </div>
    )
}
export default React.memo(AddQuesToSurveyPage);