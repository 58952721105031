import { message, DatePicker, List, ConfigProvider } from 'antd';
import React, { useEffect, useState } from 'react';
import LoadingBase from '../../components/LoadingBase';
import '../../css/listbook-page.css';


import dayjs from 'dayjs';
import ItemAppointment from './component/ItemAppointment';
import { getDaysInMonth } from '../../utils/funcHelper';
import { getListAppointment, getListShiftDetailByDate } from '../../services/apis';
import locale from 'antd/locale/vi_VN';
import { consoleLogUtil } from '../../utils/consoleLogUtil';
import ModalAppointment from './component/ModalAppointment';

const ListAppointmentPage = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [dataDate, setDataDate] = useState(getDaysInMonth(dayjs().month() + 1, dayjs().year()));
    const [dataShift, setDataShift] = useState([]);
    const [titleModal, setTitleModal] = useState('');
    
    const handleChangeMonth = (date) => {
        const tempDate = [...getDaysInMonth(date.month() + 1, date.year())];
        setDataDate([...tempDate]);
        getDataAppointment(date?.startOf('month').format('YYYY-MM-DD'), date?.endOf('month').format('YYYY-MM-DD'), tempDate);
    }
    const handleClickAction = (item) => {

    } 
    const getDataAppointment = async (startDate, endDate, lstDate) => {
        const params = {
            startDate: startDate,
            endDate: endDate
        }
        setLoading(true);
        const res = await getListAppointment(params);
        setLoading(false);
        if (res && res?.status === 1) {
            const dataAppoint = [...res?.data];
            const temp = lstDate.map(item => (
                {
                    shift_date: item,
                    count: item !== -1 ? (dataAppoint.find(app => app?.shift_date === item?.format("YYYY-MM-DD"))?.count ?? 0) : 0,
                }
            ));
            setData([...temp]);
            message.success("Lấy dữ liệu thành công")
        } else {
            setData([]);
        }
    }
    const getDataShiftByDate = async (date) => {
        const params = {
            shift_date: date
        };
        setLoading(true);
        const res = await getListShiftDetailByDate(params);
        setLoading(false);
        setShowModal(true);
        if (res && res?.status === 1) {
            setDataShift([...res?.data?.filter(item => item?.registration_status)]);
        } else {
            setDataShift([]);
        }
    }
    const handleClickAppointment = (shift_date) => {
        setTitleModal(dayjs(shift_date).format("DD/MM/YYYY"));
        getDataShiftByDate(shift_date);
    }
    useEffect(() => {
        getDataAppointment(dayjs().startOf('month').format('YYYY-MM-DD'), dayjs().endOf('month').format('YYYY-MM-DD'), dataDate);
    }, [])

    return (
        <div className='container-appointment'>
            <div style={{display: 'flex', flexDirection: 'row', flex: 1, alignItems: 'center', justifyContent: 'center', marginBottom: 12}}>
                <ConfigProvider locale={locale}>
                    <DatePicker picker='month' format={"MM/YYYY"} onChange={handleChangeMonth} defaultValue={dayjs()}/>
                </ConfigProvider>
            </div>
            <List
                grid={{ gutter: 0, column: 7 }}
                dataSource={data}
                renderItem={(item, index) => (
                    <ItemAppointment 
                        customStyled={index < 7 ? styles.borderTop: null} customStyleLeft={index % 7 === 0 ? styles.borderLeft : null}
                        index={index}
                        date={item}
                        handOnClick={handleClickAppointment}
                    />
                )}
                
            />
            <LoadingBase loading={loading}/>
            <ModalAppointment open={showModal} data={dataShift} onCancel={() => setShowModal(false)} date={titleModal}/>
        </div>
    )
}
export default React.memo(ListAppointmentPage);

const styles = {
    borderTop: {
        borderTop: 'rgb(241, 243, 244) 1px solid', 
        borderTopColor: 'rgb(218, 220, 224)'
    },
    borderLeft: {
        borderLeft: 'rgb(241, 243, 244) 1px solid', 
        borderLeftColor: 'rgb(218, 220, 224)'
    }
}