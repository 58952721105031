import { Button, Card, Checkbox, Col, Divider, Form, Image, Input, InputNumber, Row, Select, Upload, message } from "antd";
import React, { useEffect, useState } from "react";
import { requiredForm } from "../../../utils/requiredform";
import { UploadOutlined } from "@ant-design/icons";
import { Images } from "../../../assets";
import { consoleLogUtil } from "../../../utils/consoleLogUtil";
import { BASE_URL } from "../../../settings/config";
import { addNewQuestion, getDetailQuestionById, getListQuestionType, updateQuestionById } from "../../../services/apis";
import LoadingBase from "../../../components/LoadingBase";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import ItemAnswer from "../../mana-survey/component/ItemAnswer";

const AddQuestionPage = () => {
    const navigate = useNavigate();
    const userReducer = useSelector(state => state?.userReducer);
    const [form] = Form.useForm();
    const [imgAttach, setImgAttach] = useState();
    const [loading, setLoading] = useState(false);
    const [lstTypeQuestion, setLstTypeQuestion] = useState([]);
    const [lstAnswer, setLstAnswer] = useState([]);
    const [lstAnswerRoot, setLstAnswerRoot] = useState([]);
    const [numbAnswer, setNumbAnswer] = useState(0);
    const [isCheck, setCheck] = useState(false);
    const { id } = useParams();

    const props = {
        action: BASE_URL + 'upload',
        onChange(info) {
            const { file, fileList } = info;
            if (file.status === 'done') {
                setImgAttach(file?.response?.image_path);
            } else if (file.status === 'error') {
                message.error("Tải ảnh thất bại. Vui lòng thử lại")
            }
        },

    };
    const beforeUpload = (file, fileList) => {
        const isLt21M = file.size / 1024 / 1024 < 1;
        if (!isLt21M) {
            message.error('Ảnh phải có kích thước nhỏ hơn 1mb!');
            return false;
        }

        return true;
    };

    const getDataQuestionType = async () => {
        const params = {
            keyword: '',
            limit: 50,
            currentPage: 1
        }
        setLoading(true);
        const res = await getListQuestionType(params);
        setLoading(false);
        if (res && res.status === 1) {
            setLstTypeQuestion([...res?.data?.data]);
            if (id && id > 0) {
                getQuestionDetail(id);
            }
        } else {
            setLstTypeQuestion([]);
            message.error(res?.msg ?? "Lấy dữ liệu thất bại");
        }
    }

    const getQuestionDetail = async (id) => {
        setLoading(true);
        const params = {
            id: id
        };
        const res = await getDetailQuestionById(params);
        setLoading(false);
        consoleLogUtil("res detail", res);
        if (res && res?.status === 1) {
            message.success("Lấy dữ liệu câu hỏi thành công");
            form.setFieldsValue({
                question_name: res?.data?.question_name ?? '',
                category_id: res?.data?.category_id
            });
            setImgAttach(res?.data?.imgUrl);
            if (res?.data?.answers?.length > 0) {
                setCheck(true);
                setLstAnswer([...res.data.answers]);
                setLstAnswerRoot([...res.data.answers]);
            } else {
                setCheck(false);
                setLstAnswer([]);
                setLstAnswerRoot([]);
            }
        } else {
            message.error(res?.msg ?? "Lấy dữ liệu câu hỏi thất bại. Vui lòng thử lại");
        }
    }
    const updateQuestion = async (data) => {
        setLoading(true);
        const res = await updateQuestionById(data);
        setLoading(false);
        if (res && res?.status === 1) {
            message.success("Cập nhật dữ liệu thành công");
            navigate(-1);
        } else {
            message.error(res?.msg ?? "Cập nhật dữ liệu thất bại");
        }
    }
    const submit = async (data) => {
        if (lstAnswer.length === 0 || lstAnswer.filter(item => item.answer_name === null || item.score === null)?.length > 0) {
            message.error("Vui lòng nhập đầy đủ thông tin đáp án");
            return;
        }
        const params = {
            question_name: data.question_name,
            category_id: data.category_id,
            imgUrl: imgAttach,
            create_by: userReducer?.data?.info?.id,
            isHaveAnswer: isCheck ? 1 : 0,
            answers: isCheck ? lstAnswer : [],
            status: 1
        }

        if (id && id > 0) {
            params.id = id;
            await updateQuestion(params);
        } else {
            setLoading(true);
            const res = await addNewQuestion(params);
            setLoading(false);
            if (res && res.status === 1) {
                message.success("Tạo câu hỏi thành công");
                form.resetFields();
                setLstAnswer([]);
                setImgAttach();
                setNumbAnswer(0);
            } else {
                message.error(res?.msg ?? "Tạo câu hỏi thất bại. Vui lòng thử lại");
            }
        }

    }
    const handleCreateLstAnswer = () => {
        if (numbAnswer > 0) {
            const temp = Array.from({ length: numbAnswer }, () => ({
                content: null,
                score: null,
                status: 1
            }));
            setLstAnswer([...lstAnswer, ...temp]);
        }
    }

    const handleChangeTextAnswer = (value, index) => {
        const temp = [...lstAnswer];
        temp[index].content = value;
        setLstAnswer([...temp]);
    }
    const handleChangeScore = (value, index) => {
        const temp = [...lstAnswer];
        temp[index].score = value;
        setLstAnswer([...temp]);
    }
    const handleDeleteAnswer = (index) => {
        const temp = [...lstAnswer];
        temp.splice(index, 1);
        setLstAnswer([...temp]);
    }
    const filterOption = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const handleCheckBox = (value) => {
        setCheck(!isCheck);
    }

    useEffect(() => {
        getDataQuestionType();
    }, []);

    return (
        <div className='container-content'>
            <Form
                form={form}
                name='add-shift'
                onFinish={submit}
                layout={'vertical'}
            >
                <Row gutter={24}>
                    <Col span={16}>
                        <Form.Item
                            label={"Nội dung câu hỏi"}
                            rules={[requiredForm]}
                            name={"question_name"}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label={"Nhóm câu hỏi"}
                            rules={[requiredForm]}
                            name={"category_id"}
                        >
                            <Select
                                showSearch
                                placeholder={"Chọn loại câu hỏi"}
                                style={{ height: 40 }}
                                defaultActiveFirstOption={false}
                                filterOption={filterOption}
                                notFoundContent={null}
                                options={(lstTypeQuestion || []).map((d) => ({
                                    value: d.id,
                                    label: d.name,
                                }))}
                            />
                        </Form.Item>
                        <Checkbox onChange={handleCheckBox} checked={isCheck}>Đáp án riêng</Checkbox>
                        {
                            isCheck ?
                                <>
                                    <Row style={{ alignItems: 'center' }}>
                                        <Col span={24}>
                                            <Row align={"middle"}>
                                                <p style={{ fontSize: 15, fontWeight: 600, paddingRight: 12 }}>Số đáp án cho câu hỏi: </p>
                                                <InputNumber
                                                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                                    className="input-number-base1"
                                                    min={0}
                                                    onChange={(value) => setNumbAnswer(value)}
                                                />
                                                <Button type="default" style={{ marginLeft: 24, backgroundColor: 'orange' }} onClick={handleCreateLstAnswer}>Thêm đáp án</Button>
                                            </Row>

                                        </Col>
                                    </Row>
                                    {
                                        lstAnswer.length > 0 ?
                                            <Row align={"middle"} gutter={24} style={{ marginBottom: 12, marginTop: 12 }}>
                                                <Col span={2}>
                                                    <p style={{ fontWeight: 600 }}>STT</p>
                                                </Col>
                                                <Col span={14}>
                                                    <p style={{ fontWeight: 600 }}>Nội dung đáp án</p>
                                                </Col>
                                                <Col span={8}>
                                                    <p style={{ fontWeight: 600 }}>Số điểm</p>
                                                </Col>
                                            </Row> : null
                                    }

                                    <Row>
                                        <Col span={24}>
                                            {
                                                lstAnswer.length > 0 ? lstAnswer.map((item, index) => (
                                                    <ItemAnswer
                                                        index={index}
                                                        key={index.toString()}
                                                        handleChangeText={handleChangeTextAnswer}
                                                        handleChangeScore={handleChangeScore}
                                                        onDeleteAnswer={handleDeleteAnswer}
                                                        item={item}
                                                    />
                                                )) : null
                                            }
                                        </Col>
                                    </Row> 
                                </> : null
                        }

                    </Col>
                    <Col span={8}>
                        <Card
                            bordered={true}
                            style={{
                                marginTop: 8,
                                marginBottom: 8
                            }}
                            headStyle={{
                                backgroundColor: '#FFF',
                                color: "#000",
                                maxHeight: 90,
                                fontWeight: '600',
                                fontSize: 18
                            }}
                            extra={
                                <Upload {...props}
                                    maxCount={1}
                                    beforeUpload={beforeUpload}
                                    accept='image/png,image/jpg,image/jpeg'
                                    onRemove={(file) => setImgAttach(null)}
                                >
                                    <Button icon={<UploadOutlined />}>{`Up ảnh (1 file <= 1Mb)`}</Button>
                                </Upload>
                            }
                        >
                            <div style={{ display: "flex", alignItems: 'center', flex: 1, justifyContent: 'center' }}>
                                <Image src={imgAttach ? `${BASE_URL + imgAttach}` : Images.ic_no_photo} style={{ height: 130, width: '100%' }} />
                            </div>

                        </Card>

                    </Col>
                </Row>


                <Row align={"middle"} justify={"center"} style={{ marginTop: 16 }}>
                    {
                        id && id > 0 ? <Button type="default" style={{ marginRight: 16 }} danger onClick={() => navigate(-1)}>Thoát</Button> : null
                    }
                    <Button type="primary" htmlType="submit">{id && id > 0 ? "Cập nhật dữ liệu" : "Tạo câu hỏi mới"}</Button>
                </Row>
            </Form>
            <LoadingBase loading={loading} />
        </div>
    )
}
export default React.memo(AddQuestionPage);