import React from "react";
import { Images } from "../../../assets";
import dayjs from "dayjs";

const ItemFeedback = ({ data,  onSelectItem, selected, index }) => {
    return (
        <div className={selected ? "item-feedback-selected" : "item-feedback"} onClick={() => onSelectItem(data, index)}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    cursor: 'pointer',
                    padding: 8
                }}

            >
                <div>
                    <img src={Images.ic_user} style={{ width: 40, height: 40, borderRadius: 20 }} />
                </div>
                <div style={{ flex: 1, marginLeft: 8, marginRight: 4 }}>
                    <p style={{ fontWeight: '700' }}>{data?.fullname}</p>
                    <span>{data?.subject}</span>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                    <span style={{ fontSize: 11 }}>{dayjs(data?.createtime).format("DD/MM")}</span>
                    {
                        data?.unread_message_count && parseInt(data?.unread_message_count) > 0 ? 
                        <span style={{backgroundColor: 'red', width: 16, height: 16, borderRadius: 8, textAlign: 'center', color: 'white', fontSize: 10, fontWeight: '600'}}>{parseInt(data?.unread_message_count) > 9 ? `${data?.unread_message_count}+` : data?.unread_message_count}</span> 
                        :
                        null
                    }
                    
                </div>
            </div>
        </div>

    )
}
export default React.memo(ItemFeedback);