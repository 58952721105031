export const Images = {
    img_logo_app: require("../assets/logo-login.png"),
    img_truck: require("./images/truck.png"),
    img_bill: require("./images/bill.png"),
    img_wood: require("./images/wood.png"),
    img_book: require("./images/book.png"),
    img_user: require("./images/user.png"),
    img_qr: require("./images/qr.png"),
    ic_required: require("./icons/ic_require.png"),
    ic_status: require("./icons/ic_accept.png"),
    ic_doctor: require("./icons/ic_doctor.png"),
    ic_empty: require("./icons/ic_empty.png"),
    ic_student: require("./icons/ic_student.png"),
    ic_add_student: require("./icons/ic_add_student.png"),
    ic_no_photo: require("./icons/ic_no_photo.png"),
    ic_shift: require("./icons/ic_shift.png"),
    ic_register: require("./icons/register.png"),
    ic_complete: require("./icons/ic_complete.png"),
    ic_surveyor: require("./icons/surveyor.png"),
    ic_user: require("./icons/ic_user.png"),
    ic_send: require("./icons/ic_send.png"),
    bg_feedback: require("./images/bg_feedback.jpg"),
    ic_new: require("./icons/ic_new.png"),
}