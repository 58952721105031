import { Button, Card, Checkbox, Col, Collapse, Divider, Form, Image, Input, InputNumber, List, Modal, Row, Select, Space, Table, Upload, message, theme } from "antd";
import React, { useEffect, useState } from "react";
import { requiredForm } from "../../../utils/requiredform";
import { CloseOutlined, PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { Images } from "../../../assets";
import ItemAnswer from "../component/ItemAnswer";
import ItemScoreRange from "../component/ItemScoreRange";
import { consoleLogUtil } from "../../../utils/consoleLogUtil";
import { BASE_URL } from "../../../settings/config";
import { addNewSurvey, getDetailSurveyById, getListQuestion, getListQuestionType, getListSurvey, getListSurveyChild, getQuestionForSurvey, getSurveyCategory, updateSurveyAndAnswer, updateSurveyById } from "../../../services/apis";
import LoadingBase from "../../../components/LoadingBase";
import { useNavigate, useParams } from "react-router-dom";
import { filterOption } from "../../../utils/funcHelper";
import { OPTION_SURVEY } from "../../../constants/constants";
import { useSelector } from "react-redux";

const AddSurveyPage = () => {
    const { token } = theme.useToken();
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [formModal] = Form.useForm();
    const [lstAnswer, setLstAnswer] = useState([]);
    const [lstScore, setLstScore] = useState([]);
    const [numbAnswer, setNumbAnswer] = useState(0);
    const [numbScore, setNumbScore] = useState(0);
    const [imgAttach, setImgAttach] = useState();
    const [loading, setLoading] = useState(false);
    const [lstTypeSurvey, setLstTypeSurvey] = useState([]);
    const [optionSelected, setOptionSelected] = useState();
    const [lstQuestion, setLstQuestion] = useState([]);
    const [lstSurveyChild, setLstSurveyChild] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedRow, setSelectedRow] = useState([]);
    const [modalLoading, setModalLoading] = useState(false);
    const [lstTypeQuestion, setLstTypeQuestion] = useState([]);
    const [isShowModal, setIsShowModal] = useState(false);
    const [dataModal, setDataModal] = useState([]);
    const [dataModalSurvey, setDataModalSurvey] = useState([]);
    const userReducer = useSelector(state => state?.userReducer);
    const [disableSelect, setDisableSelect] = useState(false);
    const [isShowModalSurvey, setIsShowModalSurvey] = useState(false);
    const [surveySelected, setSurveySelected] = useState();
    const formStyle = {
        maxWidth: 'none',
        background: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        padding: 24,
    };
    const columnsModal = [
        {
            title: 'Mã',
            dataIndex: 'id',
            key: 'id',
            width: 60,
            align: 'center',
        },
        {
            title: optionSelected === 3 ? 'Tên khảo sát' : 'Nội dung câu hỏi',
            dataIndex: optionSelected === 3 ? 'survey_name' : 'question_name',
            key: 'id',
            render: (text) => <div style={{
                padding: 8, wordWrap: 'break-word',
                whiteSpace: 'normal'
            }}>{text}</div>
        },
        {
            title: optionSelected === 3 ? 'Loại khảo sát' : 'Nhóm',
            dataIndex: 'category_name',
            key: 'id',
            render: (text) => <div style={{ padding: 8 }}>{text}</div>
        },
        {
            title: optionSelected === 3 ? 'Số câu hỏi' : 'Đáp án riêng',
            dataIndex: optionSelected === 3 ? 'question_count' : 'isHaveAnswer',
            align: 'center',
            render: (text) => <div style={{ padding: 8 }}>{optionSelected === 3 ? text : <Checkbox checked={text === "1" ? true : false} disabled />} </div>
        },

    ];
    const columnsModalSurvey = [
        {
            title: 'Mã',
            dataIndex: 'id',
            key: 'id',
            width: 60,
            align: 'center',
        },
        {
            title: 'Nội dung câu hỏi',
            dataIndex: 'question_name',
            key: 'id',
            render: (text) => <div style={{
                padding: 8, wordWrap: 'break-word',
                whiteSpace: 'normal'
            }}>{text}</div>
        },
        {
            title: 'Nhóm',
            dataIndex: 'category_name',
            key: 'id',
            render: (text) => <div style={{ padding: 8 }}>{text}</div>
        },
        {
            title: 'Đáp án riêng',
            dataIndex: 'isHaveAnswer',
            align: 'center',
            render: (text) => <div style={{ padding: 8 }}>{<Checkbox checked={text === "1" ? true : false} disabled />} </div>
        },

    ];
    const { id } = useParams();


    const handleSelectOptionSurvey = (id) => {
        if (optionSelected !== id) {
            setLstQuestion([]);
            setSelectedRow([]);
            setSelectedRowKeys([]);
            setDataModal([]);
            setLstSurveyChild([]);
            setImgAttach();
            setDataModalSurvey([]);
            setLstAnswer([]);
            setLstScore([]);
        }
        setOptionSelected(id);
        
    }
    const props = {
        action: BASE_URL + 'upload',
        onChange(info) {
            const { file, fileList } = info;
            if (file.status === 'done') {
                setImgAttach(file?.response?.image_path);
            } else if (file.status === 'error') {
                message.error("Tải ảnh thất bại. Vui lòng thử lại")
            }
        },

    };
    const getDataQuestionType = async () => {
        const params = {
            keyword: '',
            limit: 50,
            currentPage: 1
        }
        const res = await getListQuestionType(params);
        if (res && res.status === 1) {
            setLstTypeQuestion([...res?.data?.data]);
        } else {
            setLstTypeQuestion([]);
        }
    }
    const beforeUpload = (file, fileList) => {
        const isLt21M = file.size / 1024 / 1024 < 1;
        if (!isLt21M) {
            message.error('Ảnh phải có kích thước nhỏ hơn 1mb!');
            return false;
        }

        return true;
    };
    const handleCreateScoreRange = () => {
        if (numbScore > 0) {
            const temp = Array.from({ length: numbScore }, () => ({
                conclude: null,
                max_score: null,
                min_score: null
            }));
            setLstScore([...lstScore,...temp]);
        }
    }

    const handleChangeTextScore = (value, index) => {
        const temp = [...lstScore];
        temp[index].conclude = value;
        setLstScore([...temp]);
    }
    const handleScoreMin = (value, index) => {
        const temp = [...lstScore];
        temp[index].min_score = value;
        setLstScore([...temp]);
    }
    const handleScoreMax = (value, index) => {
        const temp = [...lstScore];
        temp[index].max_score = value;
        setLstScore([...temp]);
    }
    const handleDeleteScoreRange = (index) => {
        const temp = [...lstScore];
        temp.splice(index, 1);
        setLstScore([...temp]);
    }


    const handleCreateLstAnswer = () => {
        if (numbAnswer > 0) {
            const temp = Array.from({ length: numbAnswer }, () => ({
                answer_name: null,
                score: null
            }));
            setLstAnswer([...temp]);
        }
    }
    const handleChangeTextAnswer = (value, index) => {
        const temp = [...lstAnswer];
        temp[index].answer_name = value;
        setLstAnswer([...temp]);
    }
    const handleChangeScore = (value, index) => {
        const temp = [...lstAnswer];
        temp[index].score = value;
        setLstAnswer([...temp]);
    }
    const handleDeleteAnswer = (index) => {
        const temp = [...lstAnswer];
        temp.splice(index, 1);
        setLstAnswer([...temp]);
    }
    const submit = async (data) => {

        if (optionSelected === 1 && (lstAnswer.length === 0 || lstAnswer.filter(item => item.answer_name === null || item.score === null)?.length > 0)) {
            message.error("Vui lòng nhập đầy đủ thông tin đáp án");
            return;
        }
        if (optionSelected !== 3 && lstQuestion.length === 0) {
            message.error("Vui lòng chọn câu hỏi");
            return;
        }
        if (lstScore.length === 0 || lstScore.filter(item => item.conclude === null || item.max_score === null || item.min_score === null)?.length > 0) {
            message.error("Vui lòng nhập đầy đủ thông tin thang đánh giá");
            return;
        }
        if (optionSelected === 3 && lstSurveyChild.length === 0) {
            message.error("Vui lòng chọn bài khảo sát thành phần");
            return;
        }
       // optionSelected = 1 Loại bài khảo sát có chung 1 đáp án
       // optionSelected = 2: loại bài khảo sát mà mỗi câu hỏi có đáp án và điểm riêng
       //optionSelected = 3: loại bài khảo sát gồm nhiều bài khảo sát con
        const params = {
            survey_name: data.survey_name,
            description: data.description,
            category_id: data.category_id,
            type: optionSelected,
            imgUrl: imgAttach,
            status: 1,
            answers: optionSelected === 1 ? lstAnswer : [],
            score_range: lstScore,
            question_ids: optionSelected === 3 ? null : lstQuestion.map(ques => ques.id),
            create_by: userReducer?.data?.info?.id,
            section_ids: optionSelected === 3 ? lstSurveyChild.map(sur => sur.id) : null
        };
        
        if (id && id > 0) {
            let payload = {
                ...params,
                survey_id: id
            };
            await updateStatusSuvey(payload);
        } else {
            setLoading(true);
            const res = await addNewSurvey(params);
            setLoading(false);
            if (res && res.status === 1) {
                message.success("Tạo bài khảo sát thành công");
                form.resetFields();
                setLstQuestion([]);
                setSelectedRow([]);
                setSelectedRowKeys([]);
                setDataModal([]);
                setLstSurveyChild([]);
                setImgAttach();
                setDataModalSurvey([]);
                setLstAnswer([]);
                setLstScore([]);
            } else {
                message.error(res?.msg ?? "Tạo bài khảo sát thất bại. Vui lòng thử lại");
            }
        }

    }
    const updateStatusSuvey = async (params) => {
        setLoading(true);
        const res = await updateSurveyAndAnswer(params);
        setLoading(false);
        if (res && res?.status === 1) {
            message.success("Cập nhật bài khảo sát thành công");
            setTimeout(() => {
                navigate(-1);
            }, 1000);
        } else {
            message.error(res?.msg ?? "Cập nhật bài khảo sát thất bại");
        }
    }

    const getDataSurveyChild = async (id) => {
        const params = {
            survey_parent_id: id
        };
        const res = await getListSurveyChild(params);
        if (res && res.status === 1) {
            setLstSurveyChild(res?.data ? [...res?.data] : []);
        } else {
            setLstSurveyChild([]);
        }
    }


    const getDataSurveyType = async () => {
        const params = {
            name: ''
        };
        setLoading(true);
        const res = await getSurveyCategory(params);
        setLoading(false);
        if (res && res?.status === 1) {
            setLstTypeSurvey([...res?.data]);
        } else {
            setLstTypeSurvey([]);
        }
    }

    const getDataDetailSurvey = async (id) => {
        const params = {
            id: id
        }
        setLoading(true);
        const res = await getDetailSurveyById(params);
        consoleLogUtil("getDetailSurveyById", res);
        setLoading(false);
        if (res && res?.status === 1) {
            message.success("Lấy dữ liệu thành công");
            form.setFieldsValue({
                survey_name: res?.data?.survey_name,
                description: res?.data?.description,
                category_id: res?.data?.category_id
            });
            setOptionSelected(parseInt(res?.data?.type));
            setLstAnswer([...res?.data?.answers]);
            setLstScore([...res?.data?.score_range]);
            setImgAttach(res?.data?.imgUrl);
        } else {
            message.error(res?.msg ?? "Lấy dữ liệu thất bại");
        }
    }
    const onFinish = async (data) => {
        if (optionSelected === 3) {
            getDataSurvey();
        } else {
            getDataQuestion();
        }
    };
    const getDataQuestion = async () => {
        const params = {
            keyword: formModal.getFieldValue("keyword") ?? '',
            category_id: formModal.getFieldValue("category_id") ?? '',
            limit: 200,
            currentPage: 1,
            isHaveAnswer: optionSelected === 1 ? 0 : 1
        }
        setModalLoading(true);
        const res = await getListQuestion(params);
        setModalLoading(false);
        if (res && res.status === 1) {
            setDataModal([...res?.data?.data]);
        } else {
            setDataModal([]);
            message.error(res?.msg ?? "Lấy dữ liệu thất bại");
        }
    }
    const handleShowModal = () => {
        setIsShowModal(true);
    }
    
    const handleAddQuestionOrSurvey = () => {
        if (selectedRow.length === 0) {
            message.warning("Vui lòng chọn nội dung cần thêm");
            return;
        }
        // const lstQues = itemCollap.filter(item => item.key === collapSelected)?.[0]?.children;
        if (optionSelected !== 3) {
            let newArray = lstQuestion.concat(selectedRow.filter(row => !lstQuestion.find(ques => ques.id === row.id)).map(item => ({
                ...item,
                status: 'NEW'
            })));
            setLstQuestion([...newArray]);
        } else { //Them moi danh sach survey
            let newArray = lstSurveyChild.concat(selectedRow.filter(row => !lstSurveyChild.find(sur => sur.id === row.id)).map(item => ({
                ...item,
                status: 'NEW'
            })));
            consoleLogUtil("newArray", newArray);
            setLstSurveyChild([...newArray]);
        }
        setIsShowModal(false);

        
    }
    const handleDeleteQuestion = (qsIndex) => {
        if (optionSelected !== 3) {
            const temp = [...lstQuestion];
            temp.splice(qsIndex, 1);
            setLstQuestion([...temp]);
        } else {
            const tempSur = [...lstSurveyChild];
            tempSur.splice(qsIndex, 1);
            setLstSurveyChild([...tempSur]);
        }
        
    }
    const getQuesForSurvey = async (id) => {
        setLoading(true);
        const params = {
            survey_id: id
        };
        const res = await getQuestionForSurvey(params);
        setLoading(false);
        if (res && res?.status === 1) {
            setLstQuestion([...res?.data]);
        } else {
            setLstQuestion([]);
        }
    }
    
    const getDataSurvey = async () => {
        const params = {
            keyword: formModal.getFieldValue("keyword") ?? '',
            limit: 100,
            category_id: formModal.getFieldValue("category_id") ?? '',
            currentPage: 1
        }
        setModalLoading(true);
        const res = await getListSurvey(params);
        setModalLoading(false);
        if (res && res.status === 1) {
            consoleLogUtil("resss data", res)
            setDataModal([...res?.data?.data]);
        } else {
            setDataModal([]);
            message.error(res?.msg ?? "Lấy dữ liệu thất bại");
        }
    }

    const getQuesForModalSurvey = async (id) => {
        setLoading(true);
        const params = {
            survey_id: id
        };
        const res = await getQuestionForSurvey(params);
        setLoading(false);
        if (res && res?.status === 1) {
            setDataModalSurvey([...res?.data]);
        } else {
            setDataModalSurvey([]);
        }
        setIsShowModalSurvey(true);
    }

    const handleShowModalDetailSurvey = (item) => {
        consoleLogUtil("handleShowModalDetailSurvey",item)
        setSurveySelected(item);
        getQuesForModalSurvey(item.id)
    }

    useEffect(() => {
        if (id) {
            setDisableSelect(true);
            setLoading(true);
            Promise.all([getDataDetailSurvey(id), getQuesForSurvey(id), getDataSurveyChild(id)])
                .then(res => { })
                .catch(err => consoleLogUtil("err", err))
                .finally(() => setLoading(false));
        } else {
            setDisableSelect(false);
            setOptionSelected();
            form.resetFields();
            setLstAnswer([]);
            setLstScore([]);
            setLstQuestion([]);
            setImgAttach();
        }
    }, [id])
    useEffect(() => {
        setLoading(true);
        Promise.all([getDataSurveyType(), getDataQuestionType()])
            .then(res => { })
            .catch(err => consoleLogUtil("err", err))
            .finally(() => setLoading(false));
    }, [])
    return (
        <div className='container-content'>
            <Space>
            <Select
                showSearch
                placeholder={"Chọn kiểu bài khảo sát"}
                style={{ height: 40, width: 400, marginBottom: 12 }}
                defaultActiveFirstOption={false}
                filterOption={filterOption}
                onSelect={handleSelectOptionSurvey}
                notFoundContent={null}
                value={optionSelected}
                options={OPTION_SURVEY}
                disabled={disableSelect}
            />
            {optionSelected === 3 ? <p style={{color: 'red'}}>Loại bài khảo sát này sẽ gồm nhiều thành phần, mỗi thành phần tương ứng là 1 bài khảo sát con</p> : null}
            </Space>
            
            {
                optionSelected > 0 ?
                    <Form
                        form={form}
                        name='add-shift'
                        onFinish={submit}
                        layout={'vertical'}
                    >
                        <Row gutter={24}>
                            <Col span={14}>
                                <Form.Item
                                    label={"Tên bài khảo sát"}
                                    rules={[requiredForm]}
                                    name={"survey_name"}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label={"Mô tả ngắn"}
                                    rules={[requiredForm]}
                                    name={"description"}
                                >
                                    <Input.TextArea style={{ width: '100%', height: 100 }} />
                                </Form.Item>
                                <Form.Item
                                    label={"Loại khảo sát"}
                                    rules={[requiredForm]}
                                    name={"category_id"}
                                >
                                    <Select
                                        showSearch
                                        placeholder={"Chọn loại câu hỏi"}
                                        style={{ height: 40 }}
                                        defaultActiveFirstOption={false}
                                        filterOption={filterOption}
                                        notFoundContent={null}
                                        options={(lstTypeSurvey || []).map((d) => ({
                                            value: d.id,
                                            label: d.name,
                                        }))}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={10}>
                                <Card
                                    title={`Ảnh đại diện`}
                                    bordered={true}
                                    style={{
                                        marginTop: 8,
                                        marginBottom: 8
                                    }}
                                    headStyle={{
                                        backgroundColor: '#FFF',
                                        color: "#000",
                                        maxHeight: 90,
                                        fontWeight: '600',
                                        fontSize: 18
                                    }}
                                    extra={
                                        <Upload {...props}
                                            maxCount={1}
                                            beforeUpload={beforeUpload}
                                            accept='image/png,image/jpg,image/jpeg'
                                            onRemove={(file) => setImgAttach(null)}
                                        >
                                            <Button icon={<UploadOutlined />}>{`Đính kèm file (1 file <= 1Mb)`}</Button>
                                        </Upload>
                                    }
                                >
                                    <div style={{ display: "flex", alignItems: 'center', flex: 1, justifyContent: 'center' }}>
                                        <Image src={imgAttach ? `${BASE_URL + imgAttach}` : Images.ic_no_photo} style={{ height: 130, width: '100%' }} />
                                    </div>

                                </Card>

                            </Col>
                        </Row>
                        {
                            optionSelected === 1 ?
                                <>
                                    <Divider orientation="left">Cấu hình đáp án</Divider>
                                    <Row style={{ alignItems: 'center' }}>
                                        <Col span={12}>
                                            <Row align={"middle"}>
                                                <p style={{ fontSize: 15, fontWeight: 600, paddingRight: 12 }}>Số đáp án cho mỗi câu hỏi: </p>
                                                <InputNumber
                                                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                                    className="input-number-base1"
                                                    min={0}
                                                    onChange={(value) => setNumbAnswer(value)}
                                                />
                                                <Button type="default" style={{ marginLeft: 24, backgroundColor: 'orange' }} onClick={handleCreateLstAnswer}>Tạo đáp án</Button>
                                            </Row>

                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={12}>
                                            {
                                                lstAnswer.length > 0 ?
                                                    <Row align={"middle"} gutter={24} style={{ marginBottom: 12, marginTop: 12 }}>
                                                        <Col span={2}>
                                                            <p style={{ fontWeight: 600 }}>STT</p>
                                                        </Col>
                                                        <Col span={14}>
                                                            <p style={{ fontWeight: 600 }}>Nội dung đáp án</p>
                                                        </Col>
                                                        <Col span={4}>
                                                            <p style={{ fontWeight: 600 }}>Số điểm</p>
                                                        </Col>
                                                    </Row> : null
                                            }
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col span={12}>
                                            {
                                                lstAnswer.length > 0 ? lstAnswer.map((item, index) => (
                                                    <ItemAnswer
                                                        index={index}
                                                        key={index.toString()}
                                                        handleChangeText={handleChangeTextAnswer}
                                                        handleChangeScore={handleChangeScore}
                                                        onDeleteAnswer={handleDeleteAnswer}
                                                        item={item}
                                                    />
                                                )) : null
                                            }
                                        </Col>
                                    </Row>
                                </>
                                :
                                null
                        }

                        <Divider orientation="left">Cấu hình thang đánh giá</Divider>
                        <Row style={{ alignItems: 'center' }}>
                            <Col span={12}>
                                <Row align={"middle"}>
                                    <p style={{ fontSize: 15, fontWeight: 600, paddingRight: 12 }}>Số thang đánh giá: </p>
                                    <InputNumber
                                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                        className="input-number-base1"
                                        min={0}
                                        onChange={(value) => setNumbScore(value)}
                                    />
                                    <Button type="default" style={{ marginLeft: 24, backgroundColor: 'orange' }} onClick={handleCreateScoreRange}>Tạo thang đánh giá</Button>
                                </Row>

                            </Col>
                        </Row>
                        {
                            lstScore.length > 0 ?
                                <Row align={"middle"} gutter={24} style={{ marginBottom: 12, marginTop: 12 }}>
                                    <Col span={1}>
                                        <p style={{ fontWeight: 600 }}>STT</p>
                                    </Col>
                                    <Col span={8}>
                                        <p style={{ fontWeight: 600 }}>Kết luận</p>
                                    </Col>
                                    <Col span={2}>
                                        <p style={{ fontWeight: 600 }}>Điểm Min</p>
                                    </Col>
                                    <Col span={2}>
                                        <p style={{ fontWeight: 600 }}>Điểm Max</p>
                                    </Col>
                                </Row> : null
                        }

                        <Row>
                            <Col span={24}>
                                {
                                    lstScore.length > 0 ? lstScore.map((item, index) => (
                                        <ItemScoreRange
                                            index={index}
                                            key={index.toString()}
                                            handleChangeTextScore={handleChangeTextScore}
                                            handleScoreMax={handleScoreMax}
                                            handleScoreMin={handleScoreMin}
                                            onDelete={handleDeleteScoreRange}
                                            item={item}
                                        />
                                    )) : null
                                }
                            </Col>
                        </Row>
                        <Collapse accordion expandIconPosition="end" style={{ marginTop: 12 }}>
                                    <Collapse.Panel
                                        header={
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <Space>
                                                    <Button icon={<PlusOutlined />} type="default" onClick={() => handleShowModal()}>{optionSelected === 3 ? 'Thêm survey con' : 'Thêm câu hỏi'}</Button>
                                                    {`( ${optionSelected === 3 ? lstSurveyChild.length : lstQuestion.length} )`}
                                                </Space>
                                            </div>}
                                        key={'1'}
                                    >
                                        <List
                                            size="small"
                                            dataSource={optionSelected === 3 ? lstSurveyChild : lstQuestion}
                                            renderItem={(qs, idx) => (
                                                <List.Item key={idx.toString()}>
                                                    <Space>
                                                        <Button icon={<CloseOutlined />} danger type="link" onClick={() => handleDeleteQuestion(idx)}></Button>
                                                        {
                                                            optionSelected === 3 ? 
                                                            <a onClick={() => handleShowModalDetailSurvey(qs)}>{`${idx + 1}. ${qs?.survey_name ?? ''}`}</a>
                                                            :
                                                            <p>{`${idx + 1}. ${qs?.question_name ?? ''}`}</p>
                                                            
                                                        }
                                                        {
                                                            qs?.status === "NEW" ?
                                                                <img src={Images.ic_new} style={{ width: 24, height: 24 }} /> : null
                                                        }
                                                    </Space>

                                                </List.Item>
                                            )}
                                        />
                                    </Collapse.Panel>
                        </Collapse>
                        <Row align={"middle"} justify={"center"} style={{ marginTop: 16 }}>
                            {
                                id && id > 0 ? <Button type="default" style={{ marginRight: 16 }} danger onClick={() => navigate(-1)}>Thoát</Button> : null
                            }
                            <Button type="primary" htmlType="submit">{id && id > 0 ? "Cập nhật dữ liệu" : "Tạo mới bài khảo sát"}</Button>
                        </Row>
                    </Form>
                    :
                    null
            }

            <LoadingBase loading={loading} />
            <Modal
                open={isShowModal}
                width={"80%"}
                okText={"Thêm câu hỏi"}
                cancelText="Thoát"
                onOk={() => handleAddQuestionOrSurvey()}
                onCancel={() => setIsShowModal(false)}
            >
                <Form form={formModal} name="advanced_search" style={formStyle} onFinish={onFinish}>
                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item
                                name={'keyword'}
                            >
                                <Input placeholder={'Nội dung câu hỏi'} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name={'category_id'}
                            >
                                <Select
                                    showSearch
                                    placeholder={"Chọn loại câu hỏi"}
                                    style={{ height: 40 }}
                                    defaultActiveFirstOption={false}
                                    filterOption={filterOption}
                                    notFoundContent={null}
                                    options={(lstTypeQuestion || []).map((d) => ({
                                        value: d.id,
                                        label: d.name,
                                    }))}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={4}>
                            <Space size="small">
                                <Button type="primary" htmlType="submit">
                                    Tìm kiếm
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>
                <Table
                    className='table-book'
                    rowSelection={{
                        type: 'checkbox',
                        selectedRowKeys: selectedRowKeys,
                        onChange: (selectedKeys, selectedRows) => {
                            consoleLogUtil("selectedRows", selectedRows)
                            setSelectedRow([...selectedRows]);
                            setSelectedRowKeys(selectedKeys);
                        },

                    }}
                    columns={columnsModal}
                    dataSource={dataModal}
                    pagination={{
                        pageSize: 50
                    }}
                    rowKey={(record) => record?.id}
                />
                <LoadingBase loading={modalLoading} />
            </Modal>

            <Modal
                open={isShowModalSurvey}
                width={"80%"}
                
                okText={''}
                cancelText="Thoát"
                onCancel={() => setIsShowModalSurvey(false)}
            >   
                <div>
                    <p style={{fontSize: 18, fontWeight: 700, marginBottom: 10}}>{surveySelected?.survey_name ?? ''}</p>
                    <Table
                        className='table-book'
                        columns={columnsModalSurvey}
                        dataSource={dataModalSurvey}
                        pagination={{
                            pageSize: 50
                        }}
                        rowKey={(record) => record?.id}
                    />
                </div>
                
                <LoadingBase loading={modalLoading} />
            </Modal>
            
        </div>
    )
}
export default React.memo(AddSurveyPage);