import axiosClient from "./axios.client";

export const login = (params) => {
    return axiosClient.post('authen/login', params);
}

export const saveLogUser = (params) => {
    return axiosClient.post('logUser',params);
}
export const getListSchools = (params) => {
    return axiosClient.get(`cms/category/getListSchools?keyword=${params?.keyword}&limit=${params?.limit}&currentPage=${params?.currentPage}`);
}

export const addNewSchool = (params) => {
    return axiosClient.post('cms/category/addNewSchool', params);
}

export const updateSchoolById = (params) => {
    return axiosClient.post('cms/category/updateSchools', params);
}

export const getListClass = (params) => {
    return axiosClient.get(`cms/category/getListClass?keyword=${params?.keyword}&limit=${params?.limit}&currentPage=${params?.currentPage}`);
}

export const addNewClass = (params) => {
    return axiosClient.post('cms/category/addNewClass', params);
}

export const updateClassById = (params) => {
    return axiosClient.post('cms/category/updateClass', params);
}

export const getListQuestionType = (params) => {
    return axiosClient.get(`cms/category/getListQuestionType?keyword=${params?.keyword}&limit=${params?.limit}&currentPage=${params?.currentPage}`);
}

export const addNewQuestionType = (params) => {
    return axiosClient.post('cms/category/addNewCategoryQuestion', params);
}

export const updateQuestionTypeById = (params) => {
    return axiosClient.post('cms/category/updateCategoryQuestion', params);
}

export const getListShift = (params) => {
    return axiosClient.post('cms/shift/getListShift', params);
}

export const addNewShift = (params) => {
    return axiosClient.post('cms/shift/addNewShift', params);
}

export const addListShift = (params) => {
    return axiosClient.post('cms/shift/addListShifts', params);
}

export const updateShiftById = (params) => {
    return axiosClient.post('cms/shift/updateShiftById', params);
}

export const deleteShiftById = (params) => {
    return axiosClient.post('cms/shift/deleleShiftById', params);
}

export const getListUser = (params) => {
    return axiosClient.get(`cms/account/getListUser?keyword=${params?.keyword}&type=${params?.type}`);
}

export const getListUserInfo = (params) => {
    return axiosClient.get(`cms/account/getListUserInfo?keyword=${params?.keyword}`);
}

export const updateScheduleById = (params) => {
    return axiosClient.post('mobile/mshift/updateSchedule', params);
}

export const registerSchedule = (params) => {
    return axiosClient.post('mobile/mshift/registerSchedule', params);
}

export const addNewSurvey = (params) => {
    return axiosClient.post('cms/survey/addNewSurvey', params);
}

export const getListSurvey = (params) => {
    return axiosClient.get(`cms/survey/getListSurvey?keyword=${params?.keyword}&limit=${params?.limit}&currentPage=${params?.currentPage}`);
}

export const updateSurveyById = (params) => {
    return axiosClient.post('cms/survey/updateSurvey', params);
}

export const getDetailSurveyById = (params) => {
    return axiosClient.get(`cms/survey/getDetailSurveyById?id=${params?.id}`);
}

export const updateSurveyAndAnswer = (params) => {
    return axiosClient.post('cms/survey/updateSurveyAndAnswer', params);
}

export const addNewQuestion = (params) => {
    return axiosClient.post('cms/question/addNewQuestion', params);
}

export const getListQuestion = (params) => {
    if (params.isHaveAnswer !== null) {
        return axiosClient.get(`cms/question/getListQuestion?keyword=${params?.keyword}&category_id=${params?.category_id}&limit=${params?.limit}&currentPage=${params?.currentPage}&isHaveAnswer=${params?.isHaveAnswer}`);
    } else {
        return axiosClient.get(`cms/question/getListQuestion?keyword=${params?.keyword}&category_id=${params?.category_id}&limit=${params?.limit}&currentPage=${params?.currentPage}`);
    }
}

export const getDetailQuestionById = (params) => {
    return axiosClient.get(`cms/question/getDetailQuestionById?id=${params?.id}`);
}

export const updateQuestionById = (params) => {
    return axiosClient.post('cms/question/updateQuestion', params);
}

export const addQuestionForSurvey = (params) => {
    return axiosClient.post('cms/survey/addQuestionForSurvey', params);
}

export const getQuestionForSurvey = (params) => {
    return axiosClient.get(`cms/survey/getListQuestionBySurveyId?survey_id=${params?.survey_id}`);
}

export const getListFeedbackForAdmin = (params) => {
    return axiosClient.get(`cms/feedback/getListFeedback?create_by_userid=${params?.create_by_userid}&subject=${params?.subject}&limit=${params?.limit}}&currentPage=${params?.currentPage}`);
}

export const getListMessageByFeedbackId = (params) => {
    return axiosClient.get(`cms/feedback/getMessageByFeedbackId?feedback_id=${params?.feedback_id}&limit=${params?.limit}}&currentPage=${params?.currentPage}`);
}

export const updateReadStatusMessage = (params) => {
    return axiosClient.post('cms/feedback/updateReadStatusMess', params);
}

export const sendMessageByAdmin = (params) => {
    return axiosClient.post('cms/feedback/sendMessage', params);
}

export const getSurveyCategory = (params) => {
    return axiosClient.get(`cms/category/getSurveyCategory?name=${params?.name}`);
}

export const addNewSurveyCategory = (params) => {
    return axiosClient.post('cms/category/addNewSurveyCategory',params);
}

export const updateSurveyCategory = (params) => {
    return axiosClient.post('cms/category/updateSurveyCategory',params);
}

export const getListAppointment = (params) => {
    return axiosClient.post('cms/appointment/getListAppointment',params);
}

export const getListShiftDetailByDate = params => axiosClient.post(`cms/shift/getListShift`, params);

export const getListConsultationType = () => axiosClient.get(`cms/category/getListConsultationType`);

export const getListPlaceType = () => axiosClient.get(`cms/category/getListPlaceType`);

export const getListPriority = () => axiosClient.get(`cms/category/getListPriority`);

export const getListProblemType = () => axiosClient.get(`cms/category/getListProblemType`);

export const getListStatusConsultation = () => axiosClient.get(`cms/category/getListStatusConsultation`);

export const getListRate = () => axiosClient.get(`cms/category/getListRate`);

export const getInfoUserById = params => axiosClient.post(`authen/getInfoUserById`, params);

export const createCounselingResult = params => axiosClient.post('cms/appointment/createCounselingResult',params);

export const updateCounselingResultById = params => axiosClient.post('cms/appointment/updateCounselingResultById',params);

export const getListCounselingResultByDate = (params) => axiosClient.get(`cms/appointment/getListCounselingResultByDate?date=${params?.date}`);

export const getDetailCounselingResult = (params) => axiosClient.get(`cms/appointment/getDetailCounselingResult?id=${params?.id}`);

export const getListSurveyByUserId = (params) => axiosClient.get(`mobile/survey/getListSurveyByUserId?userId=${params?.userId}`);

export const getAnswerBySurveyId = params => axiosClient.get(`mobile/survey/getAnswerBySurveyId?survey_id=${params?.survey_id}`);

export const getScoreAndResult = params => axiosClient.get(`mobile/survey/getScoreAndResult?survey_participants_id=${params?.survey_participants_id}`);

export const getResponseOfSurvey = params => axiosClient.get(`mobile/survey/getResponseOfSurvey?survey_participants_id=${params?.survey_participants_id}`);

export const checkExistCounseling = params => axiosClient.post('cms/appointment/checkExistCounseling',params);

export const getListClassBySchoolId = params => axiosClient.post('cms/category/getListClassBySchoolId',params);

export const updateUserInfo = params => axiosClient.post('cms/account/updateUserInfo',params);

export const importAccountFromExcel = params => axiosClient.post('cms/account/importAccountFromExcel',params);

export const getCountDataShift = () => axiosClient.get('cms/dashboard/getCountDataShift');

export const getCountSurveyComplete = () => axiosClient.get('cms/dashboard/getCountSurveyComplete');

export const getDataForChartDaily = () => axiosClient.get('cms/dashboard/getDataForChartDaily');

export const getListConsultCompleteRecent = () => axiosClient.get('cms/dashboard/getListConsultCompleteRecent');

export const getListRegisterScheduleRecent = () => axiosClient.get('cms/dashboard/getListRegisterScheduleRecent');

export const getListSurveyCompleteRecent = () => axiosClient.get('cms/dashboard/getListSurveyComplete');

export const getListSurveyChild = (params) => axiosClient.get(`cms/survey/getListSurveyChild?survey_parent_id=${params?.survey_parent_id}`);
