import { EditOutlined, PlusOutlined} from "@ant-design/icons"
import { Card, Col, Image, Popconfirm, Tooltip } from "antd"
import React from "react"
import Meta from "antd/es/card/Meta"
import { BASE_URL } from "../../../settings/config"

const ItemSurvey = ({ data, onDelete }) => {


    return (
        <Col span={24} style={{marginBottom: 12}}>
            <Card
                cover={
                    <Image
                        alt="example"
                        src={BASE_URL + data?.imgUrl}
                        style={{width: '100%', height: 150}}
                    />
                }
                actions={[
                    // <Tooltip title={"Thêm câu hỏi cho bài khảo sát"}>
                    //     <a href={`them-cau-hoi-survey/${data.id}`}><PlusOutlined key="add" /></a>
                    // </Tooltip>,
                    <Tooltip title={"Chỉnh sửa bài khảo sát"}>
                        <a href={`bai-khao-sat/${data.id}`}><EditOutlined key="edit" /></a>
                    </Tooltip>,
                    <Popconfirm
                        title="Xoá bài khảo sát"
                        description="Bạn có muốn xoá bài khảo sát này không?"
                        onConfirm={() => onDelete(data)}
                        okText="Có"
                        cancelText="Không"
                    >
                        <a style={{ color: 'red', textDecorationLine: 'underline' }} href="#">Xoá</a>
                    </Popconfirm>,
                ]}
            >
                <Meta
                    title={data?.survey_name?.length > 35 ? data?.survey_name?.substring(0, 35) + "..." : data?.survey_name}
                    description={data?.description?.length > 40 ? data?.description?.substring(0, 40) + "..." : data?.description}
                />
            </Card>
        </Col>


    )
}
export default React.memo(ItemSurvey)