import { Button, Card, Checkbox, Col, Collapse, Divider, Form, Image, Input, InputNumber, List, Modal, Row, Select, Space, Table, Typography, Upload, message, theme } from "antd";
import React, { useEffect, useState } from "react";
import { requiredForm } from "../../../utils/requiredform";
import { CloseOutlined, PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { Images } from "../../../assets";
import { consoleLogUtil } from "../../../utils/consoleLogUtil";
import { BASE_URL } from "../../../settings/config";
import { addNewSurvey, getDetailSurveyById, getListQuestion, getListQuestionType, getSurveyCategory, updateSurveyAndAnswer, updateSurveyById } from "../../../services/apis";
import LoadingBase from "../../../components/LoadingBase";
import { useNavigate, useParams } from "react-router-dom";
import { filterOption } from "../../../utils/funcHelper";

const AddSurveyDomainPage = () => {
    const { token } = theme.useToken();
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [formModal] = Form.useForm();
    const [lstAnswer, setLstAnswer] = useState([]);
    const [lstScore, setLstScore] = useState([]);
    const [imgAttach, setImgAttach] = useState();
    const [loading, setLoading] = useState(false);
    const [lstTypeSurvey, setLstTypeSurvey] = useState([]);
    const { id } = useParams();
    const [isShowModal, setIsShowModal] = useState(false);
    const [selectedRow, setSelectedRow] = useState([]);
    const [dataModal, setDataModal] = useState([]);
    const [modalLoading, setModalLoading] = useState(false);
    const [lstQuestion, setLstQuestion] = useState([]);
    const [lstTypeQuestion, setLstTypeQuestion] = useState([]);
    const [itemCollap, setItemCollap] = useState([]);
    const [numbSection, setNumberSection] = useState(0);
    const [collapSelected, setCollapSelected] = useState();
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const columnsModal = [
        {
            title: 'Mã',
            dataIndex: 'id',
            key: 'id',
            width: 60,
            align: 'center',
        },
        {
            title: 'Nội dung câu hỏi',
            dataIndex: 'question_name',
            key: 'id',
            render: (text) => <div style={{
                padding: 8, wordWrap: 'break-word',
                whiteSpace: 'normal'
            }}>{text}</div>
        },
        {
            title: 'Nhóm',
            dataIndex: 'category_name',
            key: 'id',
            render: (text) => <div style={{ padding: 8 }}>{text}</div>
        },
        {
            title: 'Đáp án riêng',
            dataIndex: 'isHaveAnswer',
            align: 'center',
            render: (text) => <div style={{ padding: 8 }}><Checkbox checked={text === "1" ? true : false} disabled /></div>
        },

    ];


    const formStyle = {
        maxWidth: 'none',
        background: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        padding: 24,
    };

    const onFinish = async (data) => {
        getDataQuestion();
    };
    const getDataQuestion = async () => {
        const params = {
            keyword: formModal.getFieldValue("keyword") ?? '',
            category_id: formModal.getFieldValue("category_id") ?? '',
            limit: 200,
            currentPage: 1,
            isHaveAnswer: 1
        }
        setModalLoading(true);
        const res = await getListQuestion(params);
        setModalLoading(false);
        if (res && res.status === 1) {
            setDataModal([...res?.data?.data]);
        } else {
            setDataModal([]);
            message.error(res?.msg ?? "Lấy dữ liệu thất bại");
        }
    }
    const handleDelete = (data) => {
        const checkIndex = lstQuestion.findIndex(item => item.id === data.id);
        if (checkIndex > -1) {
            const temp = [...lstQuestion];
            temp.splice(checkIndex, 1);
            setLstQuestion([...temp]);
        }
    }

    const props = {
        action: BASE_URL + 'upload',
        onChange(info) {
            const { file, fileList } = info;
            if (file.status === 'done') {
                setImgAttach(file?.response?.image_path);
            } else if (file.status === 'error') {
                message.error("Tải ảnh thất bại. Vui lòng thử lại")
            }
        },

    };
    const beforeUpload = (file, fileList) => {
        const isLt21M = file.size / 1024 / 1024 < 1;
        if (!isLt21M) {
            message.error('Ảnh phải có kích thước nhỏ hơn 1mb!');
            return false;
        }

        return true;
    };

    const submit = async (data) => {
        if (lstAnswer.length === 0 || lstAnswer.filter(item => item.answer_name === null || item.score === null)?.length > 0) {
            message.error("Vui lòng nhập đầy đủ thông tin đáp án");
            return;
        }
        if (lstScore.length === 0 || lstScore.filter(item => item.conclude === null || item.max_score === null || item.min_score === null)?.length > 0) {
            message.error("Vui lòng nhập đầy đủ thông tin thang đánh giá");
            return;
        }
        const params = {
            survey_name: data.survey_name,
            description: data.description,
            survey_type: data.survey_type,
            imgUrl: imgAttach,
            status: 1,
            answers: lstAnswer,
            score_range: lstScore
        };

        if (id && id > 0) {
            let payload = {
                ...params,
                survey_id: id
            };
            await updateStatusSuvey(payload);
        } else {
            setLoading(true);
            const res = await addNewSurvey(params);
            setLoading(false);
            if (res && res.status === 1) {
                message.success("Tạo bài khảo sát thành công");
                form.resetFields();
                setLstAnswer([]);
                setLstScore([]);
                setImgAttach(null);
            } else {
                message.error(res?.msg ?? "Tạo bài khảo sát thất bại. Vui lòng thử lại");
            }
        }

    }
    const updateStatusSuvey = async (params) => {
        setLoading(true);
        const res = await updateSurveyAndAnswer(params);
        setLoading(false);
        if (res && res?.status === 1) {
            message.success("Cập nhật bài khảo sát thành công");
            navigate(-1);
        } else {
            message.error(res?.msg ?? "Xoá bài khảo sát thất bại");
        }
    }
    const getDataQuestionType = async () => {
        const params = {
            keyword: '',
            limit: 50,
            currentPage: 1
        }
        const res = await getListQuestionType(params);
        if (res && res.status === 1) {
            setLstTypeQuestion([...res?.data?.data]);
            message.success("Lấy dữ liệu thành công");
        } else {
            setLstTypeQuestion([]);
            message.error(res?.msg ?? "Lấy dữ liệu thất bại");
        }
    }

    const getDataSurveyType = async () => {
        const params = {
            name: ''
        };
        setLoading(true);
        const res = await getSurveyCategory(params);
        setLoading(false);
        if (res && res?.status === 1) {
            setLstTypeSurvey([...res?.data]);
        } else {
            setLstTypeSurvey([]);
        }
    }

    const handleAddSection = () => {
        if (numbSection === 0) {
            return;
        }
        const temp = [...itemCollap];
        for (let i = 1; i <= numbSection; i++) {
            const itemDefault = {
                key: itemCollap.length + i,
                title: '',
                children: []
            }
            temp.push(itemDefault);
        }
        setItemCollap([...temp]);
    }
    const handleChangeNumber = (numb) => {
        setNumberSection(numb);
    }
    const getDataDetailSurvey = async (id) => {
        const params = {
            id: id
        }
        setLoading(true);
        const res = await getDetailSurveyById(params);
        setLoading(false);
        if (res && res?.status === 1) {
            message.success("Lấy dữ liệu thành công");
            form.setFieldsValue({
                survey_name: res?.data?.survey_name,
                description: res?.data?.description,
                survey_type: res?.data?.category_id
            });
            setLstAnswer([...res?.data?.answers]);
            setLstScore([...res?.data?.score_range]);
            setImgAttach(res?.data?.imgUrl);
        } else {
            message.error(res?.msg ?? "Lấy dữ liệu thất bại");
        }
    }
    const handleDeleteSection = (index) => {
        const temp = [...itemCollap];
        temp.splice(index, 1);
        setItemCollap([...temp]);
    }
    const handleShowModal = (key) => {
        setSelectedRowKeys([]);
        setCollapSelected(key);
        setIsShowModal(true);
    }
    const handleDeleteQuestion = (key, qsIndex) => {
        const lstQues = itemCollap.filter(item => item.key === key)?.[0]?.children;
        if (lstQues && lstQues?.length > 0) {
            lstQues.splice(qsIndex, 1);
        }
        const temp = [...itemCollap];
        temp.map(item => {
            if (item.key === key) {
                return {
                    ...item,
                    children: [...item.children, ...lstQues],
                };
            }
            return item;
        });
        setItemCollap([...temp]);
    }
    const handleAddQuestion = () => {
        if (selectedRow.length === 0) {
            message.warning("Vui lòng chọn câu hỏi");
            return;
        }
        const lstQues = itemCollap.filter(item => item.key === collapSelected)?.[0]?.children;
        let newArray = lstQues.concat(selectedRow.filter(row => !lstQues.find(ques => ques.id === row.id)).map(item => ({
            ...item,
            status: 'NEW'
        })));
        const newItemCollap = itemCollap.map(item => {
            if (item.key === collapSelected) {
                return {
                    ...item,
                    children: [...newArray],
                };
            }
            return item;
        });
        setItemCollap([...newItemCollap]);
        setIsShowModal(false);
    }
    const handleChangeTitle = (txt, index) => {
        consoleLogUtil("txt title", txt?.target?.value);
        consoleLogUtil("txt index", index);
        const temp = [...itemCollap];
        temp[index].title = txt?.target?.value;
        setItemCollap([...temp]);
    }
    useEffect(() => {
        if (id) {
            getDataDetailSurvey(id);
        }
    }, [id])
    useEffect(() => {
        setLoading(true);
        Promise.all([getDataSurveyType(), getDataQuestionType()])
            .then(res => { })
            .catch(err => consoleLogUtil("err", err))
            .finally(() => setLoading(false));
    }, [])
    return (
        <div className='container-content'>
            <Form
                form={form}
                name='add-shift'
                onFinish={submit}
                layout={'vertical'}
            >
                <Row gutter={24}>
                    <Col span={14}>
                        <Form.Item
                            label={"Tên bài khảo sát"}
                            rules={[requiredForm]}
                            name={"survey_name"}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label={"Mô tả ngắn"}
                            rules={[requiredForm]}
                            name={"description"}
                        >
                            <Input.TextArea style={{ width: '100%', height: 60 }} />
                        </Form.Item>
                        <Form.Item
                            label={"Loại khảo sát"}
                            rules={[requiredForm]}
                            name={"survey_type"}
                        >
                            <Select
                                showSearch
                                placeholder={"Chọn loại câu hỏi"}
                                style={{ height: 40 }}
                                defaultActiveFirstOption={false}
                                filterOption={filterOption}
                                notFoundContent={null}
                                options={(lstTypeSurvey || []).map((d) => ({
                                    value: d.id,
                                    label: d.name,
                                }))}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={10}>
                        <Card
                            title={`Ảnh đại diện`}
                            bordered={true}
                            style={{
                                marginTop: 8,
                                marginBottom: 8
                            }}
                            headStyle={{
                                backgroundColor: '#FFF',
                                color: "#000",
                                maxHeight: 90,
                                fontWeight: '600',
                                fontSize: 18
                            }}
                            extra={
                                <Upload {...props}
                                    maxCount={1}
                                    beforeUpload={beforeUpload}
                                    accept='image/png,image/jpg,image/jpeg'
                                    onRemove={(file) => setImgAttach(null)}
                                >
                                    <Button icon={<UploadOutlined />}>{`Đính kèm file (1 file <= 1Mb)`}</Button>
                                </Upload>
                            }
                        >
                            <div style={{ display: "flex", alignItems: 'center', flex: 1, justifyContent: 'center' }}>
                                <Image src={imgAttach ? `${BASE_URL + imgAttach}` : Images.ic_no_photo} style={{ height: 130, width: '100%' }} />
                            </div>

                        </Card>

                    </Col>
                </Row>
                <p style={{ marginBottom: 8 }}>Bài khảo sát có mấy phần?</p>
                <Space>
                    <InputNumber min={0} style={{ height: 40 }} onChange={handleChangeNumber} />
                    <Button type="primary" style={{ backgroundColor: 'orange' }} onClick={handleAddSection}>Thêm section</Button>
                </Space>
                <Collapse accordion expandIconPosition="end" style={{ marginTop: 12 }}>
                    {
                        itemCollap.map((item, index) => (
                            <Collapse.Panel
                                header={
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Space>
                                            <span>{`Tên mục ${index + 1}: `}</span>
                                            <Input placeholder="Nhập tên" onChange={(txt) => handleChangeTitle(txt, index)}  style={{ width: 350 }} value={item?.title}/>
                                            <Button icon={<PlusOutlined />} type="default" onClick={() => handleShowModal(item?.key)}>Thêm câu hỏi</Button>
                                            <Button icon={<CloseOutlined />} type="default" danger onClick={() => handleDeleteSection(index)}>Xoá mục này</Button>
                                        </Space>
                                    </div>}
                                key={index.toString()}
                            >
                                <List
                                    size="small"
                                    dataSource={item?.children ?? []}
                                    renderItem={(qs, idx) => (
                                        <List.Item key={idx.toString()}>
                                            <Space>
                                                <Button icon={<CloseOutlined />} danger type="link" onClick={() => handleDeleteQuestion(item.key, idx)}></Button>
                                                {`${idx + 1}.`}&nbsp;{qs?.question_name ?? ''}
                                                {
                                                    qs?.status === "NEW" ?
                                                        <img src={Images.ic_new} style={{ width: 24, height: 24 }} /> : null
                                                }
                                            </Space>

                                        </List.Item>
                                    )}
                                />
                            </Collapse.Panel>
                        ))
                    }
                </Collapse>
                <Row align={"middle"} justify={"center"} style={{ marginTop: 16 }}>
                    {
                        id && id > 0 ? <Button type="default" style={{ marginRight: 16 }} danger onClick={() => navigate(-1)}>Thoát</Button> : null
                    }
                    <Button type="primary" htmlType="submit">{id && id > 0 ? "Cập nhật dữ liệu" : "Tạo mới bài khảo sát"}</Button>
                </Row>
            </Form>
            <LoadingBase loading={loading} />
            <Modal
                open={isShowModal}
                width={"80%"}
                okText={"Thêm câu hỏi"}
                cancelText="Thoát"
                onOk={() => handleAddQuestion()}
                onCancel={() => setIsShowModal(false)}
            >
                <Form form={formModal} name="advanced_search" style={formStyle} onFinish={onFinish}>
                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item
                                name={'keyword'}
                            >
                                <Input placeholder={'Nội dung câu hỏi'} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name={'category_id'}
                            >
                                <Select
                                    showSearch
                                    placeholder={"Chọn loại câu hỏi"}
                                    style={{ height: 40 }}
                                    defaultActiveFirstOption={false}
                                    filterOption={filterOption}
                                    notFoundContent={null}
                                    options={(lstTypeQuestion || []).map((d) => ({
                                        value: d.id,
                                        label: d.name,
                                    }))}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={4}>
                            <Space size="small">
                                <Button type="primary" htmlType="submit">
                                    Tìm kiếm
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>
                <Table
                    className='table-book'
                    rowSelection={{
                        type: 'checkbox',
                        selectedRowKeys: selectedRowKeys,
                        onChange: (selectedKeys, selectedRows) => {
                            consoleLogUtil("selectedRows", selectedRows)
                            setSelectedRow([...selectedRows]);
                            setSelectedRowKeys(selectedKeys);
                        },

                    }}
                    columns={columnsModal}
                    dataSource={dataModal}
                    pagination={{
                        pageSize: 50
                    }}
                    rowKey={(record) => record?.id}
                />
                <LoadingBase loading={modalLoading} />
            </Modal>
        </div>
    )
}
export default React.memo(AddSurveyDomainPage);