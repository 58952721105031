import React from 'react';
import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";
import LoginPage from './screens/login/LoginPage';
import NotFound from './components/NotFound';
import DashboardPage from './screens/dashboard/DashboardPage';
import PrivateRoute from './components/PrivateRoute';
import ListSchoolsPage from './screens/category/school/ListSchoolsPage';
import ListClassPage from './screens/category/class/ListClassPage';
import QuestionTypePage from './screens/category/question-type/QuestionTypePage';
import ListShiftPage from './screens/mana-shift/ListShiftPage';
import AddShiftPage from './screens/mana-shift/add/AddShiftPage';
import AddSurveyPage from './screens/mana-survey/add/AddSurveyPage';
import ListSurveyPage from './screens/mana-survey/ListSurveyPage';
import AddQuestionPage from './screens/mana-question/add-edit/AddQuestionPage';
import ListQuestionPage from './screens/mana-question/ListQuestionPage';
import AddQuesToSurveyPage from './screens/mana-survey/add-question/AddQuesToSurveyPage';
import FeedBackPage from './screens/feedback/FeedBackPage';
import ListAccountPage from './screens/mana-account/ListAccountPage';
import SurveyTypePage from './screens/category/survey-type/SurveyTypePage';
import ListAppointmentPage from './screens/consultation/ListAppointmentPage';
import AddConsultationPage from './screens/consultation/add-consultation/AddConsultationPage';
import ListConsultationPage from './screens/consultation/ListConsultationPage';
import AddSurveyDomainPage from './screens/mana-survey/add/AddSurveyDomainPage';


const AppRouter = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='*' element={<NotFound />} />
                <Route path="/dang-nhap" element={
                    <LoginPage />
                } />
                <Route path="/" element={
                    <PrivateRoute>
                        <DashboardPage />
                    </PrivateRoute>
                } />
                <Route path="/dashboard" element={
                    <PrivateRoute>
                        <DashboardPage />
                    </PrivateRoute>
                } />
                <Route path="/danh-sach-truong" element={
                    <PrivateRoute>
                        <ListSchoolsPage />
                    </PrivateRoute>
                } />
                <Route path="/danh-sach-lop" element={
                    <PrivateRoute>
                        <ListClassPage />
                    </PrivateRoute>
                } />
                <Route path="/loai-cau-hoi" element={
                    <PrivateRoute>
                        <QuestionTypePage />
                    </PrivateRoute>
                } />
                <Route path="/danh-sach-ca" element={
                    <PrivateRoute>
                        <ListShiftPage />
                    </PrivateRoute>
                } />
                <Route path="/them-moi-ca" element={
                    <PrivateRoute>
                        <AddShiftPage />
                    </PrivateRoute>
                } />
                <Route path="/them-moi-survey" element={
                    <PrivateRoute>
                        <AddSurveyPage />
                    </PrivateRoute>
                } />
                <Route path="/danh-sach-survey" element={
                    <PrivateRoute>
                        <ListSurveyPage />
                    </PrivateRoute>
                } />
                <Route path="/bai-khao-sat/:id" element={
                    <PrivateRoute>
                        <AddSurveyPage />
                    </PrivateRoute>
                } />
                <Route path="/them-moi-cau-hoi" element={
                    <PrivateRoute>
                        <AddQuestionPage />
                    </PrivateRoute>
                } />
                <Route path="/danh-sach-cau-hoi" element={
                    <PrivateRoute>
                        <ListQuestionPage />
                    </PrivateRoute>
                } />
                <Route path="/chinh-sua-cau-hoi/:id" element={
                    <PrivateRoute>
                        <AddQuestionPage />
                    </PrivateRoute>
                } />
                <Route path="/them-cau-hoi-survey/:id" element={
                    <PrivateRoute>
                        <AddQuesToSurveyPage />
                    </PrivateRoute>
                } />
                <Route path="/y-kien-phan-hoi" element={
                    <PrivateRoute>
                        <FeedBackPage />
                    </PrivateRoute>
                } />
                <Route path="/quan-ly-tai-khoan" element={
                    <PrivateRoute>
                        <ListAccountPage />
                    </PrivateRoute>
                } />
                <Route path="/loai-khao-sat" element={
                    <PrivateRoute>
                        <SurveyTypePage />
                    </PrivateRoute>
                } />
                <Route path="/danh-sach-lich-hen" element={
                    <PrivateRoute>
                        <ListAppointmentPage />
                    </PrivateRoute>
                } />
                <Route path="/tu-van/:id" element={
                    <PrivateRoute>
                        <AddConsultationPage />
                    </PrivateRoute>
                } />
                <Route path="/tu-van" element={
                    <PrivateRoute>
                        <AddConsultationPage />
                    </PrivateRoute>
                } />
                <Route path="/ket-qua-tu-van" element={
                    <PrivateRoute>
                        <ListConsultationPage />
                    </PrivateRoute>
                } />
                <Route path="/them-survey-domain" element={
                    <PrivateRoute>
                        <AddSurveyDomainPage />
                    </PrivateRoute>
                } />
            </Routes>

        </BrowserRouter>
    );
};

export default AppRouter;