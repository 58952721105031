import { Button, Col, Form, Input, Row, Space, theme, Table, message } from 'antd';
import React, { useEffect, useState } from 'react';
import LoadingBase from '../../../components/LoadingBase';
import '../../../css/listbook-page.css';
import AddDataModal from './component/AddDataModal';
import { addNewClass, getListClass, updateClassById } from '../../../services/apis';
import { TYPE_MODAL } from '../../../constants/constants';
import { useSelector } from 'react-redux';
import FooterTable from '../../../components/FooterTable';

const ListClassPage = () => {
    const { token } = theme.useToken();
    const userReducer = useSelector(state => state?.userReducer);
    const [form] = Form.useForm();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [totalPage, setTotalPage] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [titleModal, setTitleModal] = useState('');
    const [itemSelected, setItemSelected] = useState();
    const [typeModal, setTypeModal] = useState();
    const columns = [
        {
            title: 'Mã',
            dataIndex: 'id',
            key: 'id',
            width: 150,
            align: 'center',
        },
        {
            title: 'Tên lớp',
            dataIndex: 'classname',
            key: 'id',
            render: (text) => <div style={{padding: 8}}>{text}</div>
        },
        {
            title: 'Tên trường',
            dataIndex: 'schoolname',
            key: 'schoolname',
            render: (text) => <div style={{padding: 8}}>{text}</div>
        },
        {
            title: 'Hành động',
            key: 'action',
            width: 80,
            align: 'center',
            render: (text, record) => <a onClick={() => handleEdit(record)} style={{}}>Sửa</a>
        },
        
    ];
    
    const formStyle = {
        maxWidth: 'none',
        background: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        padding: 24,
    };
    const handlePrev = () => {
        if (loading) {
            return;
        }
        const page = currentPage;
        setCurrentPage(page - 1);
        getDataClass(page - 1);
    }

    const handleOnSelect = (value) => {
        setCurrentPage(value);
        getDataClass(value)
    }
    const handleNext = () => {
        if (loading) {
            return;
        }
        const page = currentPage;
        setCurrentPage(page + 1);
        getDataClass(page + 1);
    }

    const handleEdit = (item) => {
        setTypeModal(TYPE_MODAL.EDIT);
        setShowModal(true);
        setTitleModal("Sửa thông tin lớp học");
        setItemSelected(item);
    }
    const getDataClass = async (currentPage) => {
        const params = {
            keyword: form.getFieldValue("keyword") ?? '',
            limit: 50,
            currentPage: currentPage
        }
        setLoading(true);
        const res = await getListClass(params);
        setLoading(false);
        if (res && res.status === 1) {
            setData([...res?.data?.data]);
            setTotalPage(res?.data?.data?.totalPages);
            message.success("Lấy dữ liệu thành công");
        } else {
            setData([]);
            message.error(res?.msg ?? "Lấy dữ liệu thất bại");
        }
    }

    const handleAddOrEditData = async (data) => {
        setShowModal(false);
        setLoading(true);
        if (typeModal === TYPE_MODAL.ADD) {
            const params = {
                classname: data?.classname,
                userId: userReducer?.data?.info?.id,
                schoolId: data?.schoolId
            };
            
            const res = await addNewClass(params);
            setLoading(false);
            if (res && res?.status === 1) {
                message.success("Thêm mới lớp học thành công");
                getDataClass(currentPage);
            } else {
                message.error(res?.msg ?? "Thêm mới thất bại!")
            }
        } else {
            const params = {
                classname: data?.classname,
                userId: userReducer?.data?.info?.id,
                schoolId: data?.school_id,
                classId: data?.id
            };
            
            const res = await updateClassById(params);
            setLoading(false);
            if (res && res?.status === 1) {
                message.success("Cập nhật lớp học thành công");
                getDataClass(currentPage);
            } else {
                message.error(res?.msg ?? "Cập nhật thất bại!")
            }
        }
    }
    const handleCancel = () => {
        setShowModal(false);
    }

    const onFinish = async (data) => {
        getDataClass(1);
    };

    useEffect(() => {
        getDataClass(1);
    }, [])

    return (
        <div className='container-content'>
            <Form form={form} name="advanced_search" style={formStyle} onFinish={onFinish}>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            name={'keyword'}
                        >
                            <Input placeholder={'Tên lớp'} />
                        </Form.Item>
                    </Col>
                    
                    
                    <Col span={4}>
                        <Space size="small">
                            <Button type="primary" htmlType="submit">
                                Tìm kiếm
                            </Button>
                            <Button
                                onClick={() => {
                                    setShowModal(true);
                                    setTitleModal("Thêm mới lớp học");
                                    setTypeModal(TYPE_MODAL.ADD)
                                }}
                            >
                                Thêm mới
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
            <Table
                className='table-book'
                columns={columns}
                dataSource={data}
                pagination={false}
                rowKey={(record) => record?.id}
            />
            <FooterTable
                totalRecord={data?.length}
                totalPage={totalPage}
                currentPage={currentPage}
                handleNext={handleNext}
                handlePrev={handlePrev}
                onSelect={handleOnSelect}
            >
            </FooterTable>
            <AddDataModal visible={showModal} onCancel={handleCancel} onSave={handleAddOrEditData} title={titleModal} type={typeModal} data={itemSelected}/>
            <LoadingBase loading={loading} />
        </div>
    )
}
export default React.memo(ListClassPage);