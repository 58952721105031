import { Button, Col, Image, Input, InputNumber, Row } from "antd";
import React from "react";
import { Images } from "../../../assets";
import dayjs from "dayjs";

const ItemSender = ({ data }) => {

    return (
        <div style={{display: 'flex', flex: 1, justifyContent: 'flex-end', marginBottom: 8}}>
            <div style={{display: 'flex', justifyContent: 'flex-end', flexDirection: 'column', maxWidth: 350}}>
                <div style={{ backgroundColor: "#e5effe", borderRadius: 8, paddingLeft: 12, paddingRight: 12, paddingTop: 12, paddingBottom: 8}}>
                    <span>{data?.message ?? ''}</span>
                </div>
                <span style={{color: "#f5f5f5", fontSize: 11, textAlign: 'end'}}>{dayjs(data?.createtime).format("HH:mm DD/MM/YYYY")}</span>
            </div>
            
        </div>


    )
}
export default React.memo(ItemSender);