import { Button, Col, Input, InputNumber, Row } from "antd";
import React from "react";

const ItemScoreRange = ({ index, handleChangeTextScore, handleScoreMin, handleScoreMax, onDelete, item }) => {

    return (
        <Row align={"middle"} gutter={24} style={{marginBottom: 12}}>
            <Col span={1} style={{textAlign: 'center'}}>
                <p>{index + 1}</p>
            </Col>
            <Col span={8}>
                <Input 
                    placeholder="Nhập kết luận" 
                    onChange={(e) => handleChangeTextScore(e.target.value, index)}
                    value={item?.conclude} 
                />
            </Col>
            <Col span={2}>
                <InputNumber
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                    className="input-number-base1"
                    min={0}
                    onChange={(value) => handleScoreMin(value, index)}
                    value={item?.min_score}
                />
            </Col>
            <Col span={2}>
                <InputNumber
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                    className="input-number-base1"
                    min={0}
                    onChange={(value) => handleScoreMax(value, index)}
                    value={item?.max_score}
                />
            </Col>
            <Col span={1}>
                <Button danger onClick={() => onDelete(index)}>Xoá</Button>
            </Col>
        </Row>
    )
}
export default React.memo(ItemScoreRange);