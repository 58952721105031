import { Button, Col, Form, Input, Row, Space, theme, Table, message, DatePicker, Modal, List } from 'antd';
import React, { useEffect, useState } from 'react';
import LoadingBase from '../../components/LoadingBase';
import '../../css/listbook-page.css';
import { updateSurveyById, getListSurvey } from '../../services/apis';
import { useSelector } from 'react-redux';
import { consoleLogUtil } from '../../utils/consoleLogUtil'
import EmptyData from '../../components/EmptyData';
import ItemSurvey from './component/ItemSurvey';

const ListSurveyPage = () => {
    const { token } = theme.useToken();
    const userReducer = useSelector(state => state?.userReducer);
    const [form] = Form.useForm();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    const formStyle = {
        maxWidth: 'none',
        background: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        padding: 24,
    };

    const getDataSurvey = async (keyword) => {
        const params = {
            keyword: keyword,
            limit: 50,
            currentPage: currentPage
        }
        setLoading(true);
        const res = await getListSurvey(params);
        setLoading(false);
        if (res && res.status === 1) {
            consoleLogUtil("resss data", res)
            setData([...res?.data?.data]);
            setTotalPages(res?.data?.totalPages);
            message.success("Lấy dữ liệu thành công");
        } else {
            setData([]);
            message.error(res?.msg ?? "Lấy dữ liệu thất bại");
        }
    }


    const onFinish = async (data) => {
        getDataSurvey(data.keyword);
    };

    const handlDeleteSurvey = (data) => {
        updateStatusSuvey(data);
    }

    const updateStatusSuvey = async (data) => {
        const params = {
            id: data.id,
            survey_name: data.survey_name,
            description: data.description,
            imgUrl: data.imgUrl,
            status: 2
        };
        setLoading(true);
        const res = await updateSurveyById(params);
        setLoading(false);
        if (res && res?.status === 1) {
            message.success("Xoá bài khảo sát thành công");
            getDataSurvey("");
        } else {
            message.error(res?.msg ?? "Xoá bài khảo sát thất bại");
        }
    }
    useEffect(() => {
        getDataSurvey("");
    }, [])

    return (
        <div className='container-content'>
            <Form form={form} name="advanced_search" style={formStyle} onFinish={onFinish}>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            name="keyword"
                        >
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col span={4}>
                        <Space size="small">
                            <Button type="primary" htmlType="submit">
                                Tìm kiếm
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>

            <Row gutter={24} style={{ backgroundColor: '#fafafa', padding: 16, borderRadius: 8, marginLeft: 0, marginRight: 0, marginTop: 8 }}>
                <List
                    rowKey={(item, index) => item?.id?.toString()}
                    grid={{ gutter: 16, column: 3 }}
                    dataSource={data}
                    renderItem={(item, index) => (
                        <ItemSurvey
                            data={item}
                            onDelete={handlDeleteSurvey}
                            key={item?.id?.toString()}
                        />
                    )
                    }
                    pagination={{
                        pageSize: 9
                    }}
                />
                {/* {
                    data.length > 0 ? data.map((item, index) => (
                        <ItemSurvey 
                            data={item}
                            onDelete={handlDeleteSurvey}
                            key={item?.id?.toString()}
                        />
                    )) : <EmptyData />
                } */}
            </Row>

            <LoadingBase loading={loading} />
        </div>
    )
}
export default React.memo(ListSurveyPage);