import { Button, Col, DatePicker, Divider, Form, Input, InputNumber, Row, Space, TimePicker, message } from "antd";
import React, { useEffect, useState } from "react"
import { requiredForm } from "../../../utils/requiredform";
import SearchInput from "../../../components/SearchInput";
import ItemCard from "./component/ItemCard";
import { addListShift, getListUser } from "../../../services/apis";
import { consoleLogUtil } from "../../../utils/consoleLogUtil";
import { useSelector } from "react-redux";
import LoadingBase from "../../../components/LoadingBase";
import { DATE_FORMAT } from "../../../constants/constants";
import EmptyData from "../../../components/EmptyData";
import moment from "moment";

const AddShiftPage = () => {
    const userReducer = useSelector(state => state?.userReducer);
    consoleLogUtil("userReducer",userReducer)
    const [form] = Form.useForm();
    const [lstUser, setLstUser] = useState([]);
    const [lstShift, setLstShift] = useState([]);
    const [loading, setLoading] = useState(false);
    const submit = async (data) => {
        createListShift();
    }

    const createListShift = () => {
        consoleLogUtil("startTime", form.getFieldValue("startTime").format("HH:mm"))
        let startTime = form.getFieldValue("startTime");

        // Mảng để lưu các ca làm việc
        let shifts = [];

        // Tạo 4 ca làm việc
        for (let i = 0; i < form.getFieldValue("totalShift"); i++) {
            // Thời gian kết thúc là thời gian bắt đầu cộng thêm 60 phút
            let endTime = startTime.add(form.getFieldValue("duration"), 'minutes');
            
            // Thêm ca làm việc vào mảng
            shifts.push({
                start_time: startTime.format('HH:mm'),
                end_time: endTime.format('HH:mm'),
                shift_date: form.getFieldValue("date")?.format(DATE_FORMAT.YYYYMMDD_FORMAT),
                user_id: form.getFieldValue("doctor"),
                create_by: userReducer?.data?.info?.id
            });
            
            // Cập nhật thời gian bắt đầu cho ca làm việc tiếp theo
            startTime = endTime;
        }
        setLstShift([...shifts])
    }

    const getDataDoctor = async () => {
        const params = {
            keyword: '',
            type: 2
        }
        const res = await getListUser(params);
        if (res && res?.status === 1) {
            setLstUser([...res?.data?.map(item => ({
                id: item.id,
                name: item.fullname
            }))]);
        } else {
            setLstUser();
        }
    }

    const handleGetUsers = async () => {
        const params = {
            keyword: form.getFieldValue("doctor") ?? '',
            type: 2
        }
        const res = await getListUser(params);
        if (res && res?.status === 1) {
            setLstUser([...res?.data?.map(item => ({
                id: item.id,
                name: item.fullname
            }))]);
        } else {
            setLstUser();
        }
    }

    const handleOnDeleteShift = (index) => {
        const temp = [...lstShift];
        temp.splice(index, 1);
        setLstShift([...temp]);
    }

    const handleOnchangeShift = (type, index, value) => {
        switch (type) {
            case "START":
                if (moment(value, "HH:mm").isBefore(moment(lstShift[index]?.end_time, "HH:mm"))) {
                    const temp = [...lstShift];
                    temp[index].start_time = value;
                    setLstShift([...temp]);
                } else {
                    message.warning("Không được sửa thời gian bắt đầu lớn hơn thời gian kết thúc");
                }
                
                break;
            case "END":
                if (moment(value, "HH:mm").isAfter(moment(lstShift[index]?.start_time, "HH:mm"))) {
                    const temp = [...lstShift];
                    temp[index].end_time = value;
                    setLstShift([...temp]);
                } else {
                    message.warning("Không được sửa thời gian kết thúc nhỏ hơn thời gian bắt đầu");
                }
                break;
        }
    }

    const handleSaveShifts = async () => {
        setLoading(true);
        const res = await addListShift(lstShift);
        setLoading(false);
        if (res && res?.status === 1) {
            message.success("Thêm mới ca làm việc thành công");
            form.resetFields();
            setLstShift([]);
        } else {
            message.error(res?.msg ?? "Có lỗi xảy ra vui lòng thử lại");
            form.resetFields();
            setLstShift([]);
        }
        consoleLogUtil("resss add data", res);
    }

    useEffect(() => {
        getDataDoctor();
    }, [])

    return (
        <div className='container-content'>
            <Form
                form={form}
                name='add-shift'
                onFinish={submit}
                layout={'vertical'}
            >
                <Row gutter={16} align={"middle"}>
                    <Col span={5}>
                        <Form.Item
                            name="doctor"
                            label="Tên chuyên gia"
                            rules={[requiredForm]}
                        >
                            <SearchInput
                                data={lstUser}
                                placeholder={"Chọn chuyên gia"}
                                customStyle={{ width: '100%', height: 40 }}
                                handleChange={(value) => form.setFieldValue("doctor", value)}
                                value={form.getFieldValue("doctor")}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            name="date"
                            label="Chọn ngày"
                            rules={[requiredForm]}
                        >
                            <DatePicker
                                format={"DD-MM-YYYY"}
                                className="input-number-base"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Giờ bắt đầu"
                            name="startTime"
                            rules={[{
                                required: true,
                                message: 'Nhập giờ'
                            }]}
                        >
                            <TimePicker placeholder={"Chọn giờ"} format={"HH:mm"} className="input-number-base" />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Số ca"
                            name="totalShift"
                            rules={[requiredForm]}
                        >
                            <InputNumber
                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                className="input-number-base"
                                min={0}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Số phút/ca"
                            name="duration"
                            rules={[requiredForm]}
                        >
                            <InputNumber
                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                className="input-number-base"
                                min={0}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={3}>
                        <Button type="primary" htmlType="submit">Tạo danh sách ca</Button>
                    </Col>

                </Row>
                {
                    lstShift.length > 0 ? <Button type="primary" style={{backgroundColor: 'red'}} onClick={handleSaveShifts}>Lưu danh sách ca</Button> : null
                }
                <Divider orientation="left" ><span className='title-divider'>Danh sách ca</span></Divider>
                <Row gutter={24} style={{backgroundColor: '#fafafa', padding: 16, borderRadius: 8, marginLeft: 16}}>
                    {
                        lstShift.length > 0 ? lstShift.map((item, index) => (
                            <ItemCard 
                                index={index} 
                                startTime={item.start_time} 
                                endTime={item.end_time} 
                                date={item.shift_date} 
                                onDelete={handleOnDeleteShift} 
                                handleOnchangeTime={handleOnchangeShift}
                                key={index.toString()}
                            />
                        )) : <EmptyData />
                    }
                </Row>
                
            </Form>
            <LoadingBase loading={loading}/>
        </div>
    )
}
export default AddShiftPage;