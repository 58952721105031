

import ReactApexChart from "react-apexcharts";
import { Row, Col, Typography } from "antd";
import eChart from "./configs/eChart";
import { useEffect, useRef } from "react";

const EChart = ({options, series}) => {
  const { Title, Paragraph } = Typography;

  
  const chartRef = useRef();

  useEffect(() => {
    if (chartRef.current && options && series) {
      // Cập nhật options và series cho biểu đồ
      chartRef.current.chart.updateOptions(options, false, true);
      chartRef.current.chart.updateSeries(series);
    }
  }, [options, series]);
  return (
    <>
      <div id="chart">
        <ReactApexChart
          ref={chartRef}
          className="bar-chart"
          options={eChart.options}
          series={eChart.series}
          type="bar"
          height={300}
        />
      </div>
      <div className="chart-vistior">
        <Title level={5}>Số lượt đăng ký trong 7 ngày gần nhất</Title>
        <Paragraph className="lastweek">
          Thống kê tổng lượt đăng ký theo từng ngày trong tuần
        </Paragraph>
      </div>
    </>
  );
}

export default EChart;
