import { CheckCircleOutlined, CheckOutlined, DeleteOutlined, DownOutlined, DownloadOutlined, PlusCircleOutlined, PlusOutlined, RightCircleOutlined } from "@ant-design/icons"
import { Button, Card, Col, Popconfirm, Row, Space, TimePicker, Tooltip, message } from "antd"
import dayjs from "dayjs"
import React, { useState } from "react"
import { DATE_FORMAT } from "../../../constants/constants"
import { Images } from "../../../assets"
import moment from "moment"

const ItemCard = ({ index, startTime, endTime, date, onDelete, handleSave, status, fullname, regName, onDeleteStudent, onAddStudent, onApprove }) => {

    const [isEdit, setIsEdit] = useState(false);
    const [start, setStart] = useState(startTime);
    const [end, setEnd] = useState(endTime);

    const handleOnchange = (type, value) => {
        
        if (type === "START") {
            if (moment(value, "HH:mm").isBefore(moment(end, "HH:mm"))) {
                setStart(value);
                setIsEdit(true);
            } else {
                message.warning("Không được sửa thời gian bắt đầu lớn hơn thời gian kết thúc");
            }
            
        } else {
            if (moment(value, "HH:mm").isAfter(moment(start, "HH:mm"))) {
                setEnd(value);
                setIsEdit(true);
            } else {
                message.warning("Không được sửa thời gian kết thúc nhỏ hơn thời gian bắt đầu");
            }
        }
    }
    const renderStatus = (status) => {
        let result = '';
        switch (status) {
            case "1":
                result = "Chờ duyệt";
                break;
            case "2":
                result = "Đã huỷ";
                break;
            case "3":
                result = "Đã duyệt";
                break;
            case "4":
                result = "Đã hoàn thành";
                break;
            default:
                result = "Mới";
                break;
        }
        return result;
    }
    const renderColorStatus = (status) => {
        let color = null;
        switch (status) {
            case "1":
                color = "#db6775";
                break;
            case "2":
                color = "red";
                break;
            case "3":
                color = "green";
                break;
            case "4":
                color = "#346eeb";
                break;
            default:
                color = "#346eeb";
                break;
        }
        return color;
    }
    const onSave = () => {
        handleSave(start, end, index);
        setIsEdit(false);
    }
    const handleDeleteStudent = () => {
        onDeleteStudent(index);
    }
    const handleAddStudent = () => {
        onAddStudent(index);
    }
    const handleApprove = () => {
        onApprove(index);
    }
    return (
        <Col span={8}>
            <Card
                type="inner"
                title={`Ca ${index + 1} - ${dayjs(date).format(DATE_FORMAT.DDMMYYYY_FORMAT)}`}
                bordered={false}
                style={{
                    marginTop: 8,
                    marginBottom: 8
                }}
                headStyle={{
                    backgroundColor: '#AFD198',
                    color: "#000",
                    height: 30,
                    fontWeight: '600',
                    fontSize: 18
                }}
                extra={
                    <Space>
                        {
                            isEdit ?
                                <Popconfirm
                                    title="Lưu ca"
                                    description="Bạn có muốn lưu lại thông tin đã sửa không?"
                                    onConfirm={onSave}
                                    okText="Có"
                                    cancelText="Không"
                                >
                                    <a style={{ color: 'blue', textDecorationLine: 'underline', paddingRight: 5 }} href="#">Lưu</a>
                                </Popconfirm>
                                : null
                        }
                        <Popconfirm
                            title="Xoá ca"
                            description="Bạn có muốn xoá ca này không?"
                            onConfirm={() => onDelete(index)}
                            okText="Có"
                            cancelText="Không"
                        >
                            <a style={{ color: 'red', textDecorationLine: 'underline' }} href="#">Xoá</a>
                        </Popconfirm>
                    </Space>}
            >
                <Row gutter={16} align="middle">
                    <Col span={10}>
                        <TimePicker allowClear={false} format={"HH:mm"} value={dayjs(start, 'HH:mm')} onChange={(value, dateString) => handleOnchange("START", dateString)} />
                    </Col>
                    <Col span={4} style={{ textAlign: 'center' }}>
                        <RightCircleOutlined />
                    </Col>
                    <Col span={10}>
                        <TimePicker allowClear={false} format={"HH:mm"} value={dayjs(end, 'HH:mm')} onChange={(value, dateString) => handleOnchange("END", dateString)} />
                    </Col>
                </Row>
                <Row align="middle" style={{ marginTop: 10 }}>
                    <img src={Images.ic_doctor} style={{ width: 14, height: 14, marginRight: 5 }} />
                    <p style={{ paddingLeft: 4, fontWeight: '500' }}>Chuyên gia:</p>
                    <span style={{ paddingLeft: 4, fontWeight: '700' }}>{fullname ?? ''}</span>
                </Row>
                <Row align="middle" style={{ marginTop: 5 }}>
                    <img src={Images.ic_status} style={{ width: 14, height: 14, marginRight: 5 }} />
                    <p style={{ paddingLeft: 4, fontWeight: '500' }}>Trạng thái:</p>
                    <span style={{ paddingLeft: 4, fontWeight: '700', color: renderColorStatus(status) }}>{renderStatus(status)}</span>
                </Row>
                <Row align="middle" style={{ marginTop: 5 }} justify={"space-between"}>
                    <Row align="middle">
                        <img src={Images.ic_student} style={{ width: 14, height: 14, marginRight: 5 }} />
                        <p style={{ paddingLeft: 4, fontWeight: '500' }}>Đăng ký:</p>
                        {
                            (status === "3" || status === "1") ? <p style={{ fontWeight: '600', paddingRight: 8, paddingLeft: 4 }}>{regName}</p> : null
                        }
                    </Row>
                    <Space>
                        {
                            status === null ?
                            <Tooltip placement="top" title={"Thêm học sinh vào ca tư vấn"}>
                                <Button type="primary" shape="round" icon={<PlusOutlined />} size={"small"} onClick={handleAddStudent}></Button>
                            </Tooltip> : null
                        }
                        {
                            status === "1" ?
                            <Tooltip placement="top" title={"Duyệt lịch hẹn"}>
                                <Button type="primary" shape="round" icon={<CheckOutlined />} size={"small"} onClick={handleApprove}></Button>
                            </Tooltip> : null
                        }
                        {
                            status && (status === "1" || status === "3") ?
                            <Tooltip placement="top" title={"Xoá/ từ chối học sinh khỏi ca tư vấn"}>
                                <Button style={{ alignSelf: 'end' }} type="primary" shape="round" danger icon={<DeleteOutlined />} size={"small"} onClick={handleDeleteStudent}></Button>
                            </Tooltip> : null
                        }
                    </Space>    
                    
                </Row>

            </Card>
        </Col>


    )
}
export default React.memo(ItemCard)