import {
    UnorderedListOutlined,
    LogoutOutlined,
    DashboardOutlined,
    PlusCircleOutlined,
    MenuFoldOutlined,
    FileDoneOutlined,
    FileSearchOutlined,
    ExceptionOutlined,
    FileTextOutlined,
    ImportOutlined,
    TeamOutlined,
    BarChartOutlined,
    TagOutlined,
    UserOutlined,
    RollbackOutlined,
    BookOutlined,
    ProjectOutlined,
    RestOutlined,
    SaveOutlined,
    MessageOutlined,
    AccountBookOutlined,
    CalendarOutlined,
    FieldTimeOutlined
} from '@ant-design/icons';

export const DATE_FORMAT = {
    DDMMYYYY_FORMAT: "DD/MM/YYYY",
    YYYYMMDD_FORMAT: "YYYY-MM-DD",
    DDMM_FORMAT: "DD/MM"
}

export const TYPE_MODAL = {
    ADD: "ADD",
    EDIT: "EDIT",
    DEL: "DEL",
    APPROVE: "APPROVE"
}

export const OPTION_SURVEY = [
    {
        value: 1,
        label: 'Bài khảo sát có chung đáp án cho các câu hỏi'
    },
    {
        value: 2,
        label: 'Bài khảo sát có riêng đáp án từng câu hỏi'
    },
    {
        value: 3,
        label: 'Bài khảo sát có nhiều thành phần (domain)'
    },
]

export const LIST_MENU_SIDE_BAR = [
    {
        key: 'dashboard',
        path: "/dashboard",
        label: 'Dashboard',
        icon: <BarChartOutlined />,
        permission: [1, 2, 3, 4]
    },
    {
        label: 'Quản lý ca',
        icon: <BookOutlined />,
        children: [
            {
                key: 'them-moi-ca',
                path: "/them-moi-ca",
                label: 'Thêm mới ca',
                icon: <PlusCircleOutlined />,
                permission: [1, 2, 3, 4]
            },
            {
                key: 'danh-sach-ca',
                path: "/danh-sach-ca",
                label: 'Danh sách ca',
                icon: <UnorderedListOutlined />,
                permission: [1, 2, 3, 4]
            },
        ]
    },
    {
        label: 'Quản lý survey',
        icon: <BookOutlined />,
        children: [
            {
                key: 'them-moi-survey',
                path: "/them-moi-survey",
                label: 'Thêm mới survey',
                icon: <PlusCircleOutlined />,
                permission: [1, 2, 3, 4]
            },
            {
                key: 'danh-sach-survey',
                path: "/danh-sach-survey",
                label: 'Danh sách survey',
                icon: <UnorderedListOutlined />,
                permission: [1, 2, 3, 4]
            },
        ]
    },
    {
        label: 'Quản lý câu hỏi',
        icon: <BookOutlined />,
        children: [
            {
                key: 'them-moi-cau-hoi',
                path: "/them-moi-cau-hoi",
                label: 'Thêm mới câu hỏi',
                icon: <PlusCircleOutlined />,
                permission: [1, 2, 3, 4]
            },
            {
                key: 'loai-cau-hoi',
                path: "/loai-cau-hoi",
                label: 'Nhóm câu hỏi',
                icon: <TagOutlined />,
                permission: [1]
            },
            {
                key: 'danh-sach-cau-hoi',
                path: "/danh-sach-cau-hoi",
                label: 'Danh sách câu hỏi',
                icon: <UnorderedListOutlined />,
                permission: [1, 2, 3, 4]
            },
        ]
    },
    {
        label: 'Quản lý lịch hẹn',
        icon: <CalendarOutlined />,
        children: [
            {
                key: 'danh-sach-lich-hen',
                path: "/danh-sach-lich-hen",
                label: 'Danh sách lịch hẹn',
                icon: <FieldTimeOutlined />,
                permission: [1, 2, 3, 4]
            },
            {
                key: 'ket-qua-tu-van',
                path: "/ket-qua-tu-van",
                label: 'Kết quả tư vấn',
                icon: <FileDoneOutlined />,
                permission: [1]
            },
        ]
    },
    {
        key: 'y-kien-phan-hoi',
        path: "/y-kien-phan-hoi",
        label: 'Ý kiến phản hồi',
        icon: <MessageOutlined />,
        permission: [1, 2, 3, 4]
    },
    {
        label: 'Báo cáo',
        icon: <ProjectOutlined />,
        children: [
            {
                key: 'bao-cao-tu-van',
                path: "/bao-cao-tu-van",
                label: 'Báo cáo tư vấn',
                icon: <FileSearchOutlined />,
                permission: [1, 2, 3, 4]
            },
        ]
    },
    {
        label: 'Danh mục',
        icon: <MenuFoldOutlined />,
        children: [
            {
                key: 'danh-sach-truong',
                path: "/danh-sach-truong",
                label: 'Trường học',
                icon: <FileSearchOutlined />,
                permission: [1]
            },
            {
                key: 'danh-sach-lop',
                path: "/danh-sach-lop",
                label: 'Lớp học',
                icon: <ExceptionOutlined />,
                permission: [1]
            },
            {
                key: 'loai-khao-sat',
                path: "/loai-khao-sat",
                label: 'Loại khảo sát',
                icon: <ExceptionOutlined />,
                permission: [1]
            },
        ]
    },
    {
        key: 'quan-ly-tai-khoan',
        path: "/quan-ly-tai-khoan",
        label: 'Quản lý tài khoản',
        icon: <TeamOutlined />,
        permission: [1]
    },
    // {
    //     key: 'doi-mat-khau',
    //     path: "/doi-mat-khau",
    //     label: 'Đổi mật khẩu',
    //     icon: <AccountBookOutlined />,
    //     permission: [1, 2, 3, 4]
    // },
    {
        key: 'dang-xuat',
        path: "/dang-xuat",
        label: 'Đăng xuất',
        icon: <LogoutOutlined />,
        permission: [1, 2, 3, 4]
    },

];

export const requiredItem = [
    {
        required: true,
        message: 'Vui lòng không để trống'
    }
];

export const SEX_VALUE = [
    {
        label: "Nam",
        value: "1", 
    },
    {
        label: "Nữ",
        value: "0", 
    }
];

export const TYPE_USER = [
    {
        label: "Quản trị viên",
        value: "0", 
    },
    {
        label: "Học sinh",
        value: "1", 
    },
    {
        label: "Chuyên gia",
        value: "2", 
    }
];