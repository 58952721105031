import { Col, Row } from "antd";
import React from "react";
import { Images } from "../assets";

const EmptyData = () => {

    return (
        
            <Col span={24} style={{textAlign: 'center', alignItems: 'center'}}>
                <img src={Images.ic_empty}/>
                <p style={{fontSize: 18, textAlign: 'center'}}>Không có dữ liệu</p>
            </Col>
        
    )
}

export default React.memo(EmptyData);