import React from "react";
import '../../../css/appointment.css';
import { Button } from "antd";
import dayjs from "dayjs";
import locale from 'antd/locale/vi_VN';

const ItemAppointment = ({ customStyled, customStyleLeft, index, date, handOnClick }) => {
    const renderDate = () => {
        let dateString = '';
        if (index < 7) {
            switch (index) {
                case 1:
                    dateString = 'Thứ 2';
                    break;
                case 2:
                    dateString = 'Thứ 3';
                    break;
                case 3:
                    dateString = 'Thứ 4';
                    break;
                case 4:
                    dateString = 'Thứ 5';
                    break;
                case 5:
                    dateString = 'Thứ 6';
                    break;
                case 6:
                    dateString = 'Thứ 7';
                    break;
                case 0:
                    dateString = 'Chủ nhật';
                    break;
            }
        } else {
            dateString = null;
        }
        return dateString;
    }
    const combinedStyles = { ...customStyled, ...customStyleLeft }
    return (
        <div className="item-appointment" style={combinedStyles}>
            <p style={styles.textThu}>{renderDate()}</p>
            <p style={styles.center}>{date?.shift_date !== -1 ? date?.shift_date.date() : null}</p>
            <div style={styles.bgText}>
                <p style={styles.txtSchedule} onClick={() => handOnClick(date?.shift_date?.format("YYYY-MM-DD"))}>{parseInt(date?.count) > 0 ? `Có ${date?.count} lịch hẹn` : null}</p>
            </div>
            
        </div>
    )
}
export default React.memo(ItemAppointment);

const styles = {
    textThu: { 
        textAlign: 'center', 
        fontSize: 14, 
        fontWeight: '600' 
    },
    txtSchedule: { 
        color: 'white',
        cursor: 'pointer'
    },
    center: {
        textAlign: 'center',
    },
    bgText: {
        backgroundColor: "#32b579", 
        borderRadius: 8, 
        paddingLeft: 8, 
        paddingRight: 8,
        marginTop: 'auto',
        marginBottom: 'auto' 
    }
}

