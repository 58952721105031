import { Button, Input, Modal } from "antd"
import React, { useEffect, useState } from "react";
import { TYPE_MODAL } from "../../../../constants/constants";

const AddDataModal = ({ visible, onCancel, onSave, title, type, data }) => {
    const [name, setName] = useState('');
    const [school, setSchool] = useState(data);
    const handleSave = () => {
        if (type === TYPE_MODAL.ADD) {
            onSave(name);
        } else {
            const temp = school;
            temp.schoolname = name;
            onSave(temp);
        }
        setName('');
    };

    useEffect(() => {
        if (type === TYPE_MODAL.ADD) {
            setName('');
        } else {
            setSchool(data);
            setName(data?.schoolname);
        }
    }, [data, type])

    return (
        <Modal
            title={title}
            open={visible}
            onCancel={onCancel}
            footer={[
                <Button key="cancel" onClick={onCancel}>
                    Hủy
                </Button>,
                <Button key="save" type="primary" onClick={handleSave}>
                    Lưu
                </Button>,
            ]}
        >
            <Input
                placeholder="Nhập tên trường"
                value={name}
                onChange={(e) => setName(e.target.value)}
            />
        </Modal>
    )
}
export default React.memo(AddDataModal);