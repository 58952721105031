import { Col, Divider, Input, Layout, List, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import ItemFeedback from "./component/ItemFeedback";
import { Images } from "../../assets";
import ItemSender from "./component/ItemSender";
import ItemReceiver from "./component/ItemReceiver";
import { consoleLogUtil } from "../../utils/consoleLogUtil";
import { getListFeedbackForAdmin, getListMessageByFeedbackId, sendMessageByAdmin, updateReadStatusMessage } from "../../services/apis";
import InfiniteScroll from 'react-infinite-scroll-component';
import LoadingBase from "../../components/LoadingBase";
import { useSelector } from "react-redux";

const FeedBackPage = () => {
    const [lstFeedback, setLstFeedback] = useState([]);
    const [loading, setLoading] = useState(false);
    const [indexSelected, setIndexSelected] = useState();
    const [itemSelected, setItemSelected] = useState();
    const [loadingMess, setLoadingMess] = useState(false);
    const [lstMessage, setLstMessage] = useState([]);
    const userReducer = useSelector(state => state?.userReducer);
    const [message, setMessage] = useState();

    const getDataMessage = async (id) => {
        const params = {
            feedback_id: id,
            limit: 50,
            currentPage: 1
        }
        setLoadingMess(true);
        const res = await getListMessageByFeedbackId(params);
        setLoadingMess(false);
        if (res && res?.status === 1) {
            setLstMessage([...res?.data?.data.sort((a, b) => parseInt(a.id) - parseInt(b.id))]);
        } else {
            setLstMessage([]);
        }
    }
    const updateReadStatus = async (id) => {
        const params = {
            feedback_id: id,
            status: 1
        }
        const res = await updateReadStatusMessage(params);
        consoleLogUtil("res update", res);
        if (res && res?.status === 1) {
            const check = lstFeedback.findIndex(item => item.id === id);
            if (check > -1) {
                const temp = [...lstFeedback];
                temp[check].unread_message_count = "0";
                setLstFeedback([...temp]);
            }
        }
    }
    const getDataFeedback = async (userId, keyword) => {
        setLoading(true);
        const params = {
            create_by_userid: userId ?? '',
            subject: keyword ?? '',
            limit: 100,
            currentPage: 1
        }
        const res = await getListFeedbackForAdmin(params);
        setLoading(false);
        if (res && res?.status === 1) {
            setLstFeedback([...res?.data?.data]);
        } else {
            setLstFeedback([]);
        }
    }

    const handleSelectItem = (item, index) => {
        setIndexSelected(index);
        setItemSelected(item);
        getDataMessage(item?.id);
        updateReadStatus(item?.id);
    }

    const sendMessage = async (data) => {
        const params = {
            feedback_id: data.id,
            sender_id: "3",
            receiver_id: data?.create_by_userid,
            message: data.message,
            read_status: 0,
            admin_read_status: 1
        }
        const temp = [...lstMessage];
        temp.push(params);
        setLstMessage([...temp]);
        setMessage('');
        const res = await sendMessageByAdmin(params);
        if (res && res?.status === 1) {

        }
    }

    const handleOnchange = (e) => {
        setMessage(e.target.value);
    }

    const handleClickSend = () => {
        if (message && message?.length > 0) {
            const data = {
                ...itemSelected,
                message: message
            }
            sendMessage(data);
        }
    }
    useEffect(() => {
        getDataFeedback();
    }, [])
    return (
        <div className='container-content'>
            <div style={{ flex: 1, height: `calc(100vh - 180px)`, maxHeight: `calc(100vh - 180px)`, display: 'flex', flexDirection: 'row' }}>
                <div style={{ maxHeight: `calc(100vh - 180px)`, overflowY: 'auto', width: 300 }}>
                    <Input.Search placeholder="Tìm kiếm" style={{ marginBottom: 8 }} />
                    <List
                        itemLayout="horizontal"
                        dataSource={lstFeedback}
                        renderItem={(item, index) => (<ItemFeedback data={item} index={index} onSelectItem={handleSelectItem} selected={index === indexSelected ? true : false} />)}
                    />
                </div>
                <div style={{ flex: 1, border: 1, borderStyle: 'solid', borderColor: "#CCC", display: 'flex', flexDirection: 'column' }}>
                    {
                        itemSelected ?
                            <>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: 16, marginTop: 8 }}>
                                    <div>
                                        <img src={Images.ic_user} style={{ width: 40, height: 40, borderRadius: 20 }} />
                                    </div>
                                    <div style={{ flex: 1, marginLeft: 8, marginRight: 4 }}>
                                        <p style={{ fontWeight: '700' }}>{itemSelected?.fullname}</p>
                                        <span>{itemSelected?.subject ?? ''}</span>
                                    </div>
                                </div>
                                <Divider style={{ marginTop: 10, marginBottom: 0 }} />
                                <div id="scrollableDiv" style={{flexDirection: 'column-reverse', backgroundColor: '#a19590', padding: 16, height: `calc(100vh - 240px)`, maxHeight: `calc(100vh - 300px)`, overflowY: 'auto', display: 'flex'}}>

                                    <InfiniteScroll
                                        dataLength={lstMessage.length}
                                        next={() => { }}
                                        hasMore={false}
                                        scrollableTarget="scrollableDiv" // ID của vùng cuộn (scrollable div) chứa danh sách
                                        style={{ display: 'flex', flexDirection: 'column-reverse' }} // Thêm các kiểu CSS cần thiết
                                        scrollToBottom={true} // Cuộn đến dưới cùng khi danh sách được render
                                    >
                                        <List
                                            itemLayout="horizontal"
                                            dataSource={lstMessage}
                                            renderItem={(item, index) => (
                                                item.sender_id === "3" ?
                                                    <ItemSender data={item} key={index.toString()} /> :
                                                    <ItemReceiver data={item} key={index.toString()} />
                                            )}
                                        />
                                    </InfiniteScroll>

                                    {
                                        loadingMess ? <Spin /> : null
                                    }
                                </div>
                                <div style={{ position: 'relative', borderWidth: 1, borderColor: "#CCC", borderStyle: 'solid' }}>
                                    <Input.TextArea value={message} placeholder="Nhập nội dung" style={{ paddingTop: 25, border: 'none' }} onChange={(value) => handleOnchange(value)} />
                                    {
                                        message && message?.length > 0 ?
                                            <div style={{ position: 'absolute', bottom: 0, top: 0, right: 20, alignItems: 'center', display: 'flex' }}>
                                                <img src={Images.ic_send} style={{ width: 30, height: 30 }} onClick={handleClickSend} />
                                            </div> : null
                                    }

                                </div>
                            </> : <img src={Images.bg_feedback} style={{ height: `calc(100vh - 180px)`, textAlign: 'center' }} />
                    }

                </div>
            </div>
            <LoadingBase loading={loading} />
        </div>
    )
}
export default React.memo(FeedBackPage);