import { Button, Input, Modal, Form, Row, Space } from "antd"
import React, { useEffect } from "react";
import { requiredForm } from "../../../../utils/requiredform";
import { TYPE_MODAL } from "../../../../constants/constants";

const AddDataModal = ({ visible, onCancel, onSave, title, type, data }) => {
    const [form] = Form.useForm();
    
    const onFinish = (dataF) => {
        if (type === TYPE_MODAL.ADD) {
            onSave(dataF);
        } else {
            const temp = data;
            temp.name = dataF.name;
            onSave(temp);
        }
    }
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    useEffect(() => {
        if (type === TYPE_MODAL.ADD && form) {
            form.resetFields();
        } else {
            form.setFieldValue("name", data?.name ?? '')
        }
    }, [type, data, visible, form])
    return (
        <Modal
            title={title}
            open={visible}
            onCancel={onCancel}
            footer={null}
        >
            <Form
                form={form}
                name="addSurveyType"
                labelCol={{
                    span: 5,
                }}
                wrapperCol={{
                    span: 19,
                }}
                labelAlign="left"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
            >
                <Form.Item
                    name="name"
                    label="Loại khảo sát"
                    rules={[requiredForm]}
                >
                    <Input
                        placeholder="Nhập loại khảo sát"
                    />
                </Form.Item>
                <Row justify={"end"}>
                    <Space>
                    <Button type="default" onClick={onCancel}>
                        Thoát
                    </Button>
                    <Button type="primary" htmlType="submit">
                        Lưu
                    </Button>
                    </Space>
                </Row>
                
            </Form>
        </Modal>
    )
}
export default React.memo(AddDataModal);