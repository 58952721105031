import { Button, Col, Input, InputNumber, Row } from "antd";
import React from "react";

const ItemAnswer = ({ index, handleChangeText, onDeleteAnswer, handleChangeScore, item }) => {

    return (
        <Row align={"middle"} gutter={24} style={{marginBottom: 12}}>
            <Col span={2} style={{textAlign: 'center'}}>
                <p>{index + 1}</p>
            </Col>
            <Col span={14}>
                <Input 
                    placeholder="Nhập đáp án" 
                    onChange={(e) => handleChangeText(e.target.value, index)}
                    value={item?.answer_name}
                />
            </Col>
            <Col span={4}>
                <InputNumber
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                    className="input-number-base1"
                    min={0}
                    onChange={(value) => handleChangeScore(value, index)}
                    value={item?.score}
                />
            </Col>
            <Col span={2}>
                <Button danger onClick={() => onDeleteAnswer(index)}>Xoá</Button>
            </Col>
        </Row>
    )
}
export default React.memo(ItemAnswer);