import { Button, Col, Collapse, Divider, Empty, Form, Image, Input, InputNumber, Modal, Row, Select, Space, Upload, message, theme } from "antd";
import React, { useEffect, useState } from "react";
import { Images } from "../../../assets";
import { CalendarOutlined, CheckOutlined, DeleteOutlined, EditOutlined, EnvironmentOutlined, HomeOutlined, UploadOutlined } from "@ant-design/icons";
import { filterOption } from "../../../utils/funcHelper";
import TextArea from "antd/es/input/TextArea";
import { BASE_URL } from "../../../settings/config";
import { createCounselingResult, getAnswerBySurveyId, getDetailCounselingResult, getInfoUserById, getListConsultationType, getListPlaceType, getListPriority, getListProblemType, getListRate, getListStatusConsultation, getListSurveyByUserId, getQuestionForSurvey, getResponseOfSurvey, getScoreAndResult, updateCounselingResultById } from "../../../services/apis";
import { consoleLogUtil } from "../../../utils/consoleLogUtil"
import LoadingBase from '../../../components/LoadingBase';
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { requiredItem } from "../../../constants/constants";
import ViewSurveyResult from "../component/ViewSurveyResult";

const AddConsultationPage = () => {
    const [form] = Form.useForm();
    const { token } = theme.useToken();
    const [listFile, setListFile] = useState([]);
    const [consulType, setConsulType] = useState([]);
    const [placeTypes, setPlaceTypes] = useState([]);
    const [priorities, setPriorities] = useState([]);
    const [problemTypes, setProblemTypes] = useState([]);
    const [statusConsul, setStatusConsul] = useState([]);
    const [rates, setRates] = useState([]);
    const [loading, setLoading] = useState(false);
    const [patientInfo, setPatientInfo] = useState();
    const [disabled, setDisabled] = useState(false);
    const [lstAttachOld, setLstAttachOld] = useState([]);
    const [detailCounseling, setDetailCounseling] = useState();
    const [open, setOpen] = useState(false);
    const [dataSurvey, setDataSurvey] = useState([]);
    const [dataCollap, setDataCollap] = useState([]);
    const [dataAnswer, setDataAnswer] = useState([]);
    const [dataQues, setDataQues] = useState([]);
    const [loadingModal, setLoadingModal] = useState(false);
    const [dataScore, setDataScore] = useState();
    const userReducer = useSelector(state => state?.userReducer);
    consoleLogUtil("userReducer", userReducer);
    const { id } = useParams();
    
    const shiftReducer = useSelector(state => state?.shiftReducer);
    const formStyle = {
        maxWidth: 'none',
        background: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        padding: 24,
    };

    const getDataAnswer = async (surveyId) => {
        const params = {
            survey_id: surveyId
        };
        const res = await getAnswerBySurveyId(params);
        if (res && res?.status === 1) {
            setDataAnswer([...res?.data?.map((item, index) => (
                {
                    ...item,
                    label: item?.answer_name,
                    value: item?.id,
                    disable: true
                }
            ))]);
        } else {
            setDataAnswer([]);
        }
        return res;
    }
    const getDataQuestion = async (survey_id) => {
        const params = {
            survey_id: survey_id
        };
        const res = await getQuestionForSurvey(params);
        if (res && res?.status === 1) {
            setDataQues([...res?.data]);
        } else {
            setDataQues([]);
        }
        return res;
    }
    const getDataSurveyByUserId = async ( id ) => {
        const params = {
            userId: id
        };
        const res = await getListSurveyByUserId(params);
        if (res && res?.status === 1) {
            setDataSurvey([...res?.data]);
            setDataCollap([...res?.data?.map(item => ({
                key: item?.id,
                label: `${item?.survey_name} - ${item?.createtime ? dayjs(item?.createtime).format("HH:mm DD/MM/YYYY") : null}`,
                children: null,
            }))]);
        } else {
            setDataCollap([]);
            setDataSurvey([]);
        }
    }

    const getDataDetailCounseling = async () => {
        const params = {
            id: id
        };
        setLoading(true);
        const res = await getDetailCounselingResult(params);
        setLoading(false);
        if (res && res?.status === 1) {
            const detail = res?.data;
            setDetailCounseling({ ...detail });
            form.setFieldsValue({
                problemType: detail?.problemTypeId,
                status: detail?.statusId,
                priority: detail?.priorityId,
                place: detail?.placeId,
                timeSpent: detail?.timeSpent,
                teacherInfo: detail?.teacherInfo ?? '',
                familyInfo: detail?.familyInfo ?? '',
                studentInfo: detail?.patientInfo ?? '',
                commentOfDoctor: detail?.doctorComment ?? '',
                result: detail?.resultSupport ?? '',
                rate: detail?.patientRateId,
                placeSave: detail?.placeSave,
                consultation: detail?.consultation_types ?? []
            });
            setLstAttachOld(detail?.attachments ? [...detail?.attachments] : []);
        } else {
            setDetailCounseling();
        }
        return res;
    }
    const getDataResult = async (id) => {
        const params = {
            survey_participants_id: id
        };
        const res = await getScoreAndResult(params);
        if (res && res?.status === 1) {
            setDataScore(res?.data);
        } else {
            setDataScore();
        }
        return res;
    }
    const getDataResponse = async (survey_participants_id) => {
        const params = {
            survey_participants_id: survey_participants_id
        };
        const res = await getResponseOfSurvey(params);
        return res
    }
    const updateCounseling = async (data) => {
        const params = {
            id: id,
            problemTypeId: data?.problemType,
            statusId: data?.status,
            priorityId: data?.priority,
            placeId: data?.place,
            doctorId: detailCounseling?.doctorId,
            patientId: detailCounseling?.patientId,
            shift_registration_id: detailCounseling?.shift_registration_id,
            shift_id: detailCounseling?.shift_id,
            timeSpent: data?.timeSpent,
            teacherInfo: data?.teacherInfo ?? '',
            familyInfo: data?.familyInfo ?? '',
            patientInfo: data?.studentInfo ?? '',
            doctorComment: data?.commentOfDoctor ?? '',
            resultSupport: data?.result ?? '',
            patientRateId: data?.rate,
            placeSave: data?.placeSave,
            updateByUserId: userReducer?.data?.info?.id,
            consultation_types: data?.consultation ?? [],
            attachments: [...lstAttachOld, ...listFile.filter(item => item.status === "done").map(item => ({
                file_path: item?.response?.image_path,
                status: 1
            }))]
        };
        setLoading(true);
        const res = await updateCounselingResultById(params);
        setLoading(false);
        if (res && res?.status === 1) {
            message.success("Lưu dữ liệu thành công");
        } else {
            message.error(res?.msg ?? "Lưu dữ liệu thất bại. Vui lòng thử lại.");
        }
    }

    const createNewCounselingResult = async (data) => {
        const params = {
            problemTypeId: data?.problemType,
            statusId: data?.status,
            priorityId: data?.priority,
            placeId: data?.place,
            doctorId: shiftReducer?.data?.user_id,
            patientId: shiftReducer?.data?.register_user_id,
            shift_registration_id: shiftReducer?.data?.regis_id,
            shift_id: shiftReducer?.data?.id,
            timeSpent: data?.timeSpent,
            teacherInfo: data?.teacherInfo ?? '',
            familyInfo: data?.familyInfo ?? '',
            patientInfo: data?.studentInfo ?? '',
            doctorComment: data?.commentOfDoctor ?? '',
            resultSupport: data?.result ?? '',
            patientRateId: data?.rate,
            placeSave: data?.placeSave,
            consultation_types: data?.consultation ?? [],
            createByUserId: userReducer?.data?.info?.id,
            attachments: listFile.filter(item => item.status === "done").map(item => ({
                file_path: item?.response?.image_path,
                status: 1
            }))
        };
        setLoading(true);
        const res = await createCounselingResult(params);
        setLoading(false);
        if (res && res?.status === 1) {
            message.success("Lưu kết quả tư vấn thành công");
            setDisabled(true);
        } else {
            setDisabled(false);
            message.error("Lưu dữ liệu thất bại");
        }
    }

    const getInfoPatient = async (userId) => {
        const params = {
            user_id: userId
        };
        const res = await getInfoUserById(params);
        if (res && res?.status === 1) {
            setPatientInfo(res?.data?.profile);
        } else {
            setPatientInfo();
        }
    }

    const getDataConsultationType = async () => {
        const res = await getListConsultationType();
        if (res && res?.status === 1) {
            setConsulType([...res?.data]);
        } else {
            setConsulType([]);
        }
    }

    const getDataPlaceType = async () => {
        const res = await getListPlaceType();
        if (res && res?.status === 1) {
            setPlaceTypes([...res?.data]);
        } else {
            setPlaceTypes([])
        }
    }

    const getDataPriority = async () => {
        const res = await getListPriority();
        if (res && res?.status === 1) {
            setPriorities([...res?.data]);
        } else {
            setPriorities([]);
        }
    }

    const getDataProblemType = async () => {
        const res = await getListProblemType();
        if (res && res?.status === 1) {
            setProblemTypes([...res?.data]);
        } else {
            setProblemTypes([]);
        }
    }

    const getDataStatusConsultation = async () => {
        const res = await getListStatusConsultation();
        if (res && res?.status === 1) {
            setStatusConsul([...res?.data]);
        } else {
            setStatusConsul([]);
        }
    }

    const getDataRate = async () => {
        const res = await getListRate();
        if (res && res?.status === 1) {
            setRates([...res?.data]);
        } else {
            setRates([]);
        }
    }
    const props = {
        action: BASE_URL + 'upload',
        onChange(info) {
            const { file, fileList } = info;
            setListFile([...fileList?.map(item => {
                let temp = item;
                if (!temp?.status) {
                    temp['status'] = 'error'
                }
                return temp;
            })]);
        },

    };
    const deleteLstAttachOld = (index) => {
        const temp = lstAttachOld;
        temp.splice(index, 1);
        setLstAttachOld([...temp]);
    }
    const beforeUpload = (file, fileList) => {
        const isLt21M = file.size / 1024 / 1024 < 6;
        if (!isLt21M) {
            message.error('Ảnh phải có kích thước nhỏ hơn 5mb!');
            return false;
        }

        return true;
    };
    const handlePreview = async (file) => {

    };
    const onFinish = (data) => {
        if (id > 0) {
            updateCounseling(data);
        } else {
            createNewCounselingResult(data);
        }
    }
    const onCancel = () => {
        setOpen(false);
    }
    const handleShowModal = () => {
        setOpen(true);
    }
    const handleChangeCollapse = (value) => {
        const surveyId = dataSurvey.find(item => item?.id === value?.[0])?.survey_id;
        const participant_id = dataSurvey.find(item => item?.id === value?.[0])?.id;

        if (surveyId && parseInt(surveyId) > 0) {
            setLoadingModal(true);
            Promise.all([getDataQuestion(surveyId), getDataAnswer(surveyId), getDataResult(participant_id), getDataResponse(participant_id)])
                .then(res => {
                    const temp = [...dataCollap];
                    const checkIndex = temp.findIndex(val => val.key === value?.[0]);
                    if (checkIndex > -1) {
                        temp[checkIndex].children = <ViewSurveyResult dataAnswer={[...res?.[1]?.data?.map((item, index) => (
                            {
                                ...item,
                                label: item?.answer_name,
                                value: item?.id,
                                disable: true
                            }
                        ))]} dataQues={res?.[0]?.data} dataScore={res?.[2]?.data} time={dataSurvey?.[checkIndex]?.createtime} dataResponse={res?.[3]?.data}/>
                        setDataCollap([...temp]);
                    }
                })
                .catch(err => consoleLogUtil("err", err))
                .finally(() => setLoadingModal(false))
        }
    }
    useEffect(() => {
        setLoading(true);
        Promise.all([getDataConsultationType(), getDataPlaceType(), getDataPriority(), getDataProblemType(), getDataStatusConsultation(), getDataRate()])
            .then(res => {
                if (id > 0) {
                    return getDataDetailCounseling();
                } else {
                    getInfoPatient(shiftReducer?.data?.register_user_id);
                    getDataSurveyByUserId(shiftReducer?.data?.register_user_id);
                }
            })
            .then(res2 => {
                if (id > 0) {
                    getInfoPatient(res2?.data?.patientId);
                    getDataSurveyByUserId(res2?.data?.patientId);
                }
            })
            .catch(err => consoleLogUtil("errr", err))
            .finally(() => setLoading(false));
    }, [])
    return (
        <div style={{ display: 'flex', minHeight: '100vh', flexDirection: 'column' }}>
            <Form
                form={form}
                name="add-consultation"
                onFinish={onFinish}
                style={formStyle}
                layout="vertical"
                disabled={disabled}
            >
                <div style={{ display: 'flex', marginBottom: 8, flexDirection: 'row', justifyContent: 'space-between', padding: 16 }}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <Image src={patientInfo?.avatar ? (BASE_URL + patientInfo?.avatar) : Images.ic_doctor} style={{ width: 40, height: 40, borderRadius: 20 }} />
                        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 12 }}>
                            <p style={{ fontSize: 16, fontWeight: '600' }}>{patientInfo?.fullname ?? ''}</p>
                            <p><CalendarOutlined /> {patientInfo?.birthday ? dayjs(patientInfo?.birthday).format("DD/MM/YYYY") : ''}</p>
                            <p><HomeOutlined /> {patientInfo?.schoolname ?? ''}</p>
                            <p><EnvironmentOutlined /> {`${patientInfo?.address ?? ''} - ${patientInfo?.phone ?? ''}`}</p>
                        </div>
                    </div>
                    <Space>
                        <Button type="primary" htmlType="submit" disabled={disabled}>
                            <CheckOutlined />
                            Lưu
                        </Button>
                        <Button onClick={handleShowModal}>
                            <EditOutlined />
                            Xem KQ khảo sát
                        </Button>
                    </Space>
                </div>
                <Divider style={{ marginTop: 0 }}></Divider>
                <Row gutter={24}>
                    <Col span={5}>
                        <Form.Item
                            name={'problemType'}
                            label={'Kiểu vấn đề'}
                            rules={requiredItem}
                        >
                            <Select
                                showSearch
                                placeholder={'Chọn'}
                                defaultActiveFirstOption={false}
                                filterOption={filterOption}
                                notFoundContent={null}
                                options={problemTypes.map((d) => ({
                                    value: d.id,
                                    label: d.name,
                                }))}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Form.Item
                            name={'status'}
                            label={'Trạng thái'}
                            rules={requiredItem}
                        >
                            <Select
                                showSearch
                                placeholder={'Chọn'}
                                defaultActiveFirstOption={false}
                                filterOption={filterOption}
                                notFoundContent={null}
                                options={statusConsul.map((d) => ({
                                    value: d.id,
                                    label: d.name,
                                }))}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Form.Item
                            name={'priority'}
                            label={'Mức ưu tiên'}
                            rules={requiredItem}
                        >
                            <Select
                                showSearch
                                placeholder={'Chọn'}
                                defaultActiveFirstOption={false}
                                filterOption={filterOption}
                                notFoundContent={null}
                                options={priorities.map((d) => ({
                                    value: d.id,
                                    label: d.name,
                                }))}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Form.Item
                            name={'place'}
                            label={'Địa điểm'}
                            rules={requiredItem}
                        >
                            <Select
                                showSearch
                                placeholder={'Chọn'}
                                defaultActiveFirstOption={false}
                                filterOption={filterOption}
                                notFoundContent={null}
                                options={placeTypes.map((d) => ({
                                    value: d.id,
                                    label: d.name,
                                }))}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            name={'timeSpent'}
                            label={'Thời gian(giờ)'}
                        >
                            <InputNumber min={0} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            name={'teacherInfo'}
                            label={'Thông tin GVCN'}
                        >
                            <TextArea rows={4} className="text-input-area-cs" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name={'familyInfo'}
                            label={'Thông tin gia đình thân chủ'}
                            rules={requiredItem}
                        >
                            <TextArea rows={4} className="text-input-area-cs" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item
                            name={'studentInfo'}
                            label={'Thông tin chung cá nhân'}
                            rules={requiredItem}
                        >
                            <TextArea rows={5} className="text-input-area-cs" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            name={'commentOfDoctor'}
                            label={'Nhận định của quản lý ca'}
                            rules={requiredItem}
                        >
                            <TextArea rows={4} className="text-input-area-cs" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name={'result'}
                            label={'Kết quả sau hỗ trợ'}
                        >
                            <TextArea rows={4} className="text-input-area-cs" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={10}>
                        <Form.Item
                            name={'consultation'}
                            label={'Kết luận vấn đề gặp phải'}
                            rules={requiredItem}
                        >
                            <Select
                                showSearch
                                mode="multiple"
                                placeholder={'Chọn'}
                                defaultActiveFirstOption={false}
                                filterOption={filterOption}
                                notFoundContent={null}
                                options={consulType.map((d) => ({
                                    value: d.id,
                                    label: d.name,
                                }))}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={6}>
                        <Form.Item
                            name={'rate'}
                            label={'Thân chủ đánh giá'}
                        >
                            <Select
                                showSearch
                                placeholder={'Chọn'}
                                defaultActiveFirstOption={false}
                                filterOption={filterOption}
                                notFoundContent={null}
                                options={rates.map((d) => ({
                                    value: d.id,
                                    label: d.name,
                                }))}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            name={'placeSave'}
                            label={'Vị trí lưu hồ sơ'}
                        >
                            <Input style={{ height: 33 }} className="text-input-area-cs" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <Upload {...props}
                            listType='picture'
                            maxCount={10}
                            fileList={listFile}
                            onPreview={handlePreview}
                            multiple
                            beforeUpload={beforeUpload}
                            accept='image/png,image/jpg,image/jpeg,.pdf'
                            onRemove={(file) => {

                            }}
                        >
                            <Button icon={<UploadOutlined />}>{`Đính kèm file (10 files, 1 file <= 5Mb)`}</Button>
                        </Upload>
                        {
                            lstAttachOld ? lstAttachOld.map((item, index) => (
                                <div className='row-attach item-attach' key={index.toString()}>
                                    <div className='info-attach'>
                                        <Image src={BASE_URL + item?.file_path} width={40} height={40} />
                                        <p style={{ paddingLeft: 12 }}>File đính kèm {index + 1}</p>
                                    </div>
                                    <Space>
                                        <Button size='small' onClick={() => deleteLstAttachOld(index)}><DeleteOutlined /></Button>
                                        {/* <Button disabled={false} size='small' onClick={() => handleDownloadFile(BASE_URL + "api/" + item?.file_path)}><DownloadOutlined /></Button> */}
                                    </Space>
                                </div>

                            )) : null
                        }
                    </Col>
                </Row>
            </Form>
            <div>

            </div>
            <LoadingBase loading={loading} />
            <Modal
                width={'80%'}
                open={open}
                onCancel={onCancel}
                onOk={onCancel}
                footer={[
                    <Button key="link" onClick={onCancel}>
                        Đóng
                    </Button>
                ]}
            >
                <h3 style={{ marginBottom: 18 }}>{`Danh sách bài khảo sát đã làm`}</h3>
                {
                    dataCollap.length > 0 ?
                    <Collapse accordion items={dataCollap} onChange={handleChangeCollapse}/>
                    :
                    <Empty description={"Không có dữ liệu khảo sát"} />
                }
                <LoadingBase loading={loadingModal}/>
            </Modal>
        </div>
    )
}
export default React.memo(AddConsultationPage);