import { Button, Input, Modal, Form, Row, Col, Space, message } from "antd"
import React, { useEffect, useState } from "react";
import SearchInput from "../../../../components/SearchInput";
import { getListSchools } from "../../../../services/apis";
import { requiredForm } from "../../../../utils/requiredform";
import LoadingBase from "../../../../components/LoadingBase";
import { TYPE_MODAL } from "../../../../constants/constants";

const AddDataModal = ({ visible, onCancel, onSave, title, type, data }) => {
    console.log("data", data)
    const [lstSchool, setLstSchool] = useState([]);
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const handleSave = () => {

    };

    const handleGetDataSchool = async (keyword) => {

    }
    const getDataSchool = async () => {
        setLoading(true);
        const params = {
            limit: 50,
            currentPage: 1,
            keyword: ''
        };
        const res = await getListSchools(params);
        setLoading(false);
        if (res && res?.status === 1) {
            setLstSchool([...res.data?.data?.map(item => ({
                id: item.id,
                name: item.schoolname
            }))]);
            if (type === TYPE_MODAL.ADD) {
                form.resetFields();
            } else {
                form.setFieldValue("classname", data?.classname);
                form.setFieldValue("schoolId", data?.school_id);
            }
        } else {
            setLstSchool([]);
            message.error("Không lấy được dữ liệu trường học. Vui lòng thử lại")
        }
    }
    const onFinish = (dataF) => {
        console.log('Success:', dataF);
        if (type === TYPE_MODAL.ADD) {
            onSave(dataF);
        } else {
            const temp = data;
            temp.school_id = dataF.schoolId;
            temp.classname = dataF.classname;
            onSave(temp);
        }
    }
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    useEffect(() => {
        getDataSchool();
    }, [type, data, visible])
    return (
        <Modal
            title={title}
            open={visible}
            onCancel={onCancel}
            footer={null}
        >
            <Form
                form={form}
                name="addclass"
                labelCol={{
                    span: 5,
                }}
                wrapperCol={{
                    span: 19,
                }}
                labelAlign="left"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
            >
                <Form.Item
                    name="classname"
                    label="Tên lớp"
                    rules={[requiredForm]}
                >
                    <Input
                        placeholder="Nhập tên lớp"
                    />
                </Form.Item>
                <Form.Item
                    name="schoolId"
                    label="Tên trường"
                    rules={[requiredForm]}
                >
                    <SearchInput
                        data={lstSchool}
                        handleSearchData={handleGetDataSchool}
                        placeholder={"Chọn trường học"}
                        customStyle={{ width: '100%', height: 40 }}
                        handleChange={(value) => form.setFieldValue("schoolId", value)}
                        value={form.getFieldValue("schoolId")}
                    />
                </Form.Item>
                <Row justify={"end"}>
                    <Space>
                    <Button type="default" onClick={oncancel}>
                        Thoát
                    </Button>
                    <Button type="primary" htmlType="submit">
                        Lưu
                    </Button>
                    </Space>
                </Row>
                
            </Form>
            <LoadingBase loading={loading} />
        </Modal>
    )
}
export default React.memo(AddDataModal);