import { Button, Col, Form, Input, Row, Space, theme, Table, message, Modal, Select, Radio, Upload, Image, DatePicker } from 'antd';
import React, { useEffect, useState } from 'react';
import LoadingBase from '../../components/LoadingBase';
import '../../css/listbook-page.css';
import { SEX_VALUE, TYPE_MODAL, TYPE_USER, requiredItem } from '../../constants/constants';
import { getListClassBySchoolId, getListSchools, getListUserInfo, importAccountFromExcel, updateUserInfo } from '../../services/apis';
import { consoleLogUtil } from '../../utils/consoleLogUtil';
import { ExclamationCircleOutlined, ImportOutlined, SaveOutlined, UploadOutlined } from '@ant-design/icons';
import { BASE_URL } from '../../settings/config';
import dayjs from 'dayjs';
import * as XLSX from 'xlsx';

const ListAccountPage = () => {
    const { token } = theme.useToken();
    const [form] = Form.useForm();
    const [formUser] = Form.useForm();
    const [data, setData] = useState([]);
    const [dataSchool, setDataSchool] = useState([]);
    const [dataClass, setDataClass] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [itemSelected, setItemSelected] = useState();
    const [typeModal, setTypeModal] = useState();
    const [typeUser, setTypeUser] = useState();
    const [avatar, setAvatar] = useState();
    const [loadingModal, setLoadingModal] = useState(false);
    const [isShowButton, setIsShowButton] = useState(false);
    const [loadingImport, setLoadingImport] = useState(false);
    const [dataImport, setDataImport] = useState([]);
    const [modal, contextHolder] = Modal.useModal();

    const columns = [
        {
            title: 'Mã',
            dataIndex: 'user_id',
            key: 'user_id',
            width: 150,
            align: 'center',
        },
        {
            title: 'Tài khoản',
            dataIndex: 'username',
            key: 'username',
            render: (text) => <div style={{ paddingTop: 8, paddingBottom: 8 }}>{text}</div>
        },
        {
            title: 'Họ tên',
            dataIndex: 'fullname',
            key: 'fullname',
            render: (text) => <div style={{ paddingTop: 8, paddingBottom: 8 }}>{text}</div>
        },
        {
            title: 'Số ĐT',
            dataIndex: 'phone',
            key: 'phone',
            render: (text) => <div style={{ paddingTop: 8, paddingBottom: 8 }}>{text}</div>
        },
        {
            title: 'Đối tượng',
            dataIndex: 'type',
            key: 'type',
            render: (text) => <div style={{ paddingTop: 8, paddingBottom: 8 }}>{text === "1" ? "Học sinh" : (text === "2" ? "Chuyên gia" : "Admin")}</div>
        },
        {
            title: 'Hành động',
            key: 'action',
            render: (text, record) => <a onClick={() => handleEdit(record)} style={{ paddingTop: 8, paddingBottom: 8 }}>Sửa</a>
        },

    ];

    const formStyle = {
        maxWidth: 'none',
        background: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        padding: 24,
    };

    const propsImport = {
        name: 'file',
        accept: '.xlsx,.xls',
        beforeUpload: (file) => {
            console.log('beforeUpload', file)
            setLoadingImport(true);
            let reader = new FileReader();
            reader.onload = function (e) {
                let data = e.target.result;
                let readedData = XLSX.read(data, { type: 'binary' });
                const wsname = readedData.SheetNames[0];
                const ws = readedData.Sheets[wsname];

                /* Convert array to json*/
                const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
                console.log("dataParse", dataParse)
                if (dataParse && dataParse.length > 0) {
                    dataParse.shift();
                    setDataImport([...dataParse]);
                    setIsShowButton(true);
                }
                setLoadingImport(false);
            };
            reader.readAsBinaryString(file);
            return false;
        },
    };
    const renderContentResultImport = (data, dataFailed) => {
        let str = '';
        if (dataFailed?.length > 0) {
            str = `Import thành công ${data.length - dataFailed.length} tài khoản. ${dataFailed.length} thất bại gồm: ${dataFailed.join(', ')}`;
        } else {
            str = `Import thành công ${data.length} tài khoản. ${dataFailed.length} thất bại.`
        }
        return str;
    }
    const handleSaveInfo = async () => {
        if (dataImport.length <= 0) {
            message.warning("Không có dữ liệu để import");
            return;
        }
        setLoading(true);
        const res = await importAccountFromExcel(dataImport);
        setLoading(false);
        if (res && res?.status === 1) {
            message.success("Import tài khoản thành công");
            modal.success({
                title: 'Thông báo',
                icon: <ExclamationCircleOutlined />,
                content: renderContentResultImport(dataImport, res?.data?.failedUsers),
            });
        } else {
            message.success("Import tài khoản thất bại");
        }
        
    }
    const handleEdit = (item) => {
        formUser.resetFields();
        consoleLogUtil("setItemSelected", item)
        setTypeModal(TYPE_MODAL.EDIT);
        setItemSelected(item);
        const userInfo = item?.type === "1" ? item?.student_info : item?.info_user;
        setTypeUser(item?.type);
        formUser.setFieldsValue({
            username: item?.username ?? '',
            fullname: item?.fullname ?? '',
            phone: item?.phone ?? '',
            sex: item?.sex,
            type: item?.type,
            birthday: userInfo?.birthday ? dayjs(userInfo?.birthday) : null,
            motherPhone: userInfo?.mother_phone ?? '',
            fatherPhone: userInfo?.father_phone ?? '',
            address: userInfo?.address ?? '',
            school: userInfo?.school_id,
            class: userInfo?.class_id,
            level: userInfo?.level ?? '',
            address1: userInfo?.address ?? '',
        });
        if (item?.type === "1" && userInfo?.school_id) {
            getDataClass(userInfo?.school_id);
        }
        setShowModal(true);
    }

    const getDataUsers = async (keyword, disable) => {
        const params = {
            keyword: keyword
        }
        if (!disable) {
            setLoading(true);
        }
        const res = await getListUserInfo(params);
        if (!disable) {
            setLoading(false);
        }
        if (res && res.status === 1) {
            setData([...res?.data]);
        } else {
            setData([]);
            message.error(res?.msg ?? "Lấy dữ liệu thất bại");
        }
    }

    const getDataSchool = async (keyword) => {
        const params = {
            keyword: '',
            limit: 100,
            currentPage: 1
        }
        const res = await getListSchools(params);
        if (res && res.status === 1) {
            setDataSchool([...res?.data?.data?.map(item => ({
                ...item,
                label: item.schoolname,
                value: item.id
            }))]);
        } else {
            setDataSchool([]);
        }
    }
    const getDataClass = async (school_id, disable) => {
        const params = {
            school_id
        }
        if (!disable) {
            setLoadingModal(true);
        }
        const res = await getListClassBySchoolId(params);
        if (!disable) {
            setLoadingModal(false);
        }
        if (res && res.status === 1) {
            setDataClass([...res?.data?.map(item => ({
                ...item,
                label: item.classname,
                value: item.id
            }))]);
        } else {
            setDataClass([]);
        }
    }
    const updateDataUser = async (dataUser) => {
        const params = {
            id: itemSelected?.user_id,
            username: dataUser?.username,
            fullname: dataUser?.fullname,
            phone: dataUser?.phone,
            type: dataUser?.type,
            sex: dataUser?.sex,
        };
        if (dataUser?.type === "1") {
            params.school_id = dataUser.school;
            params.class_id = dataUser.class;
            params.birthday = dataUser.birthday ? dataUser.birthday?.format("YYYY-MM-DD") : null;
            params.address = dataUser.address;
            params.mother_phone = dataUser.motherPhone;
            params.father_phone = dataUser.fatherPhone;
        }

        if (dataUser?.type === "2") {
            params.info_user_address = dataUser.address1;
            params.level = dataUser.level;
            if (avatar && avatar?.response && avatar?.response?.image_path) {
                params.avatar = avatar?.response?.image_path;
            }
        }
        setLoadingModal(true);
        const res = await updateUserInfo(params);
        setLoadingModal(false);
        if (res && res?.status === 1) {
            setShowModal(false);
            message.success("Cập nhật dữ liệu thành công");
        } else {
            message.warning(res?.msg ?? "Cập nhật dữ liệu thất bại");
        }
    }
    const onCancel = () => {
        setShowModal(false);
    }
    const onChangeTypeUser = (val) => {
        setTypeUser(val.target.value);
    }
    const onFinish = async (data) => {
        getDataUsers(data?.name);
    };
    const onFinishSaveUser = async (data) => {
        updateDataUser(data);
    }

    const handleOnSelectSchool = (schoolId) => {
        formUser.setFieldValue("class", null);
        getDataClass(schoolId);
    }
    const props = {
        action: BASE_URL + 'upload',
        onChange(info) {
            const { file, fileList } = info;
            setAvatar(file);
        },

    };
    const beforeUpload = (file, fileList) => {
        const isLt21M = file.size / 1024 / 1024 < 6;
        if (!isLt21M) {
            message.error('Ảnh phải có kích thước nhỏ hơn 5mb!');
            return false;
        }

        return true;
    };

    useEffect(() => {
        setLoading(true);
        Promise.all([getDataUsers('', true), getDataSchool()])
            .catch(err => consoleLogUtil("errr", err))
            .finally(() => setLoading(false))
    }, [])

    return (
        <div className='container-content'>
            {
                contextHolder
            }
            <Row className="row-button">
                <Upload {...propsImport} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', alignContent: 'center' }}>
                    <Button type="default" size="large" block danger icon={<ImportOutlined />} disabled={loadingImport} onClick={() => { }}
                        style={{ width: 150 }}
                    >
                        Import Excel
                    </Button>
                </Upload>
                {
                    isShowButton ?
                        <Button type="primary" size="large" defaultBg="#ff0000" block icon={<SaveOutlined />} disabled={loading} onClick={handleSaveInfo}
                            style={{ width: 150 }}
                        >
                            Lưu thông tin
                        </Button> : null
                }

            </Row>
            <Form form={form} name="advanced_search" style={formStyle} onFinish={onFinish}>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            name={'name'}
                        >
                            <Input placeholder={'Tên tài khoản'} />
                        </Form.Item>
                    </Col>


                    <Col span={4}>
                        <Space size="small">
                            <Button type="primary" htmlType="submit">
                                Tìm kiếm
                            </Button>
                            {/* <Button
                                onClick={() => {
                                    setShowModal(true);
                                    setTitleModal("Thêm mới trường học");
                                    setTypeModal(TYPE_MODAL.ADD)
                                }}
                            >
                                Thêm mới
                            </Button> */}
                        </Space>
                    </Col>
                </Row>
            </Form>
            <Table
                className='table-book'
                columns={columns}
                dataSource={data}
                pagination={{
                    pageSize: 50
                }}
                rowKey={(record) => record?.id}
            />
            <LoadingBase loading={loading} />
            <Modal
                width={'80%'}
                open={showModal}
                onCancel={onCancel}
                footer={null}
            >
                <Form
                    form={formUser}
                    name="user_info"
                    style={formStyle}
                    onFinish={onFinishSaveUser}
                    layout="vertical"
                >
                    <p style={{ color: "black", fontWeight: '600', fontSize: 16, paddingBottom: 10 }}>Thông tin tài khoản</p>
                    <Row gutter={16}>
                        <Col span={4}>
                            <Form.Item
                                rules={requiredItem}
                                name={'username'}
                                label={'Tài khoản'}
                            >
                                <Input disabled />
                            </Form.Item>
                        </Col>
                        <Col span={7}>
                            <Form.Item
                                name={'fullname'}
                                label={'Họ và tên'}
                                rules={requiredItem}
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={4}>
                            <Form.Item
                                name={'phone'}
                                label={'Số điện thoại'}
                            >
                                <Input maxLength={11} />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                name={'sex'}
                                label={'Giới tính'}
                                rules={requiredItem}
                            >
                                <Select
                                    options={SEX_VALUE}
                                    style={{ height: 40 }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={5}>
                            <Form.Item
                                name={'password'}
                                label={'Mật khẩu'}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Form.Item
                            name={'type'}
                            label={'Loại tài khoản'}
                            rules={requiredItem}
                        >
                            <Radio.Group options={TYPE_USER} onChange={onChangeTypeUser} disabled />
                        </Form.Item>

                    </Row>
                    {
                        typeUser === "1" ?
                            <>
                                <Row gutter={16}>
                                    <Col span={7}>
                                        <Form.Item
                                            name={'school'}
                                            label={'Trường học'}
                                        >
                                            <Select
                                                style={{ height: 40 }}
                                                options={dataSchool}
                                                onSelect={handleOnSelectSchool}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={5}>
                                        <Form.Item
                                            name={'class'}
                                            label={'Lớp'}
                                        >
                                            <Select
                                                style={{ height: 40 }}
                                                options={dataClass}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={4}>
                                        <Form.Item
                                            name={'birthday'}
                                            label={'Ngày sinh'}
                                        >
                                            <DatePicker format={"DD/MM/YYYY"} style={{ height: 40 }} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={4}>
                                        <Form.Item
                                            name={'motherPhone'}
                                            label={'Số điện thoại mẹ'}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={4}>
                                        <Form.Item
                                            name={'fatherPhone'}
                                            label={'Số điện thoại bố'}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={14}>
                                        <Form.Item
                                            name={'address'}
                                            label={'Địa chỉ'}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </>

                            : null
                    }
                    {
                        typeUser === "2" ?
                            <>
                                <Row gutter={16}>
                                    <Col span={5}>
                                        <Form.Item
                                            name={'level'}
                                            label={'Chức danh'}
                                            rules={requiredItem}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={19}>
                                        <Form.Item
                                            name={'address1'}
                                            label={'Địa chỉ'}
                                            rules={requiredItem}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={12}>
                                        <Upload {...props}
                                            listType='picture'
                                            maxCount={1}
                                            beforeUpload={beforeUpload}
                                            accept='image/png,image/jpg,image/jpeg'
                                            onRemove={(file) => {

                                            }}
                                        >
                                            <Button icon={<UploadOutlined />}>{`Chọn ảnh đại diện`}</Button>
                                        </Upload>
                                        {
                                            avatar ? null
                                                :
                                                itemSelected?.avatar ? (
                                                    <div className='row-attach item-attach'>
                                                        <div className='info-attach'>
                                                            <Image src={BASE_URL + itemSelected?.avatar} width={40} height={40} />
                                                            <p style={{ paddingLeft: 12 }}>Ảnh đại diện </p>
                                                        </div>
                                                    </div>

                                                ) : null
                                        }
                                    </Col>
                                </Row>
                            </>
                            : null
                    }
                    <Row justify={"end"}>
                        <Space>
                            <Button type='primary' htmlType='submit'>Lưu</Button>
                            <Button onClick={onCancel}>Đóng</Button>
                        </Space>
                    </Row>

                </Form>
                <LoadingBase loading={loadingModal} />
            </Modal>
        </div>
    )
}
export default React.memo(ListAccountPage);