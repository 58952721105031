import React, { useState } from "react";
import { Select } from 'antd';

const SearchInput = ({placeholder, customStyle, handleSearchData, data, handleChange, value}) => {
    
    const handleSearch = (newValue) => {
      handleSearchData && handleSearchData(newValue);
    };
    const filterOption = (input, option) =>
      (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
    return (
      <Select
        value={value}
        showSearch
        placeholder={placeholder}
        style={customStyle}
        defaultActiveFirstOption={false}
        filterOption={filterOption}
        onSearch={handleSearch}
        onChange={handleChange}
        notFoundContent={null}
        options={(data || []).map((d) => ({
          value: d.id,
          label: d.name,
        }))}
      />
    );
  };
  export default React.memo(SearchInput);