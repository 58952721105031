import { Button, Image, Modal, Table } from "antd"
import React, { useState } from "react";
import { BASE_URL } from "../../../settings/config";
import { useDispatch } from "react-redux";
import { shiftSelected } from "../../../redux/action";
import { consoleLogUtil } from "../../../utils/consoleLogUtil";
import LoadingBase from "../../../components/LoadingBase";
import { checkExistCounseling } from "../../../services/apis";

const ModalAppointment = ({ open, onCancel, data, date }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    const checkExistData = async (shift_registration_id, data) => {
        setLoading(true);
        const params = {
            shift_registration_id: shift_registration_id
        };
        const res = await checkExistCounseling(params);
        setLoading(false);
        if (res && res?.status === 1) {
            const url = window.location.origin + `/tu-van/${res?.data?.id}`;
            window.open(url, '_blank');
        } else {
            dispatch(shiftSelected(data));
            const url = window.location.origin + `/tu-van`;
            window.open(url, '_blank');
        }
    }

    const renderStatus = (status) => {
        let txtStatus = '';
        switch (status) {
            case "1":
                txtStatus = "Chờ duyệt";
                break;
            case "3":
                txtStatus = "Đã duyệt";
                break;
            case "4":
                txtStatus = "Hoàn thành";
                break;
        }
        return txtStatus;
    }
    const columns = [
        {
            title: 'Ảnh avatar',
            dataIndex: 'avatar',
            width: 100,
            align: 'center',
            render: (text) => <Image src={BASE_URL + text} style={{width: 60, height: 60, borderRadius: 30}}/>
        },
        {
            title: 'Người đăng ký',
            dataIndex: 'registration_fullname',
            key: 'registration_fullname',
            render: (text) => <div style={{ paddingTop: 8, paddingBottom: 8 }}>{text}</div>
        },
        {
            title: 'Khung giờ',
            render: (text, record) => <div style={{ paddingTop: 8, paddingBottom: 8 }}>{`${record?.start_time} - ${record?.end_time}`}</div>
        },
        {
            title: 'Trạng thái',
            dataIndex: 'registration_status',
            render: (text, record) => <div style={{ paddingTop: 8, paddingBottom: 8 }}>{renderStatus(text)}</div>
        },
        {
            title: 'Chuyên gia',
            dataIndex: 'doctor_fullname',
            render: (text, record) => <div style={{ paddingTop: 8, paddingBottom: 8 }}>{text}</div>
        },
        {
            title: 'Hành động',
            key: 'action',
            render: (text, record) => <a onClick={() => handleClickShift(record)} style={{ paddingTop: 8, paddingBottom: 8 }} >{record?.registration_status === "1" ? "" : (record?.registration_status === "3" ? "Tư vấn" : "Xem")}</a>
        },
    ];
    const handleClickShift = (data) => {
        consoleLogUtil("data click", data);
        checkExistData(data?.regis_id, data);
    }
    return (
        <Modal
            width={'80%'}
            open={open}
            onCancel={onCancel}
            onOk={onCancel}
            footer={[
                <Button onClick={onCancel}>
                    Đóng
                </Button>
            ]}
        >
            <h3 style={{marginBottom: 18}}>{`Danh sách lịch hẹn ngày ${date}`}</h3>
            <Table
                columns={columns}
                dataSource={data}
                pagination={false}
                rowKey={(record) => record?.id}
            />
            <LoadingBase loading={loading}/>
        </Modal>
    )
}

export default React.memo(ModalAppointment);