import { Image, Radio } from "antd";
import React from "react";
import { BASE_URL } from "../../../settings/config";

const ItemSurvey = ({onChange, value, dataAnswer, dataQues, index}) => {
    return (
        <div style={{marginLeft: 16, marginTop: 8}}>
            {
                dataQues?.imgUrl ? <Image src={BASE_URL + dataQues?.imgUrl} style={{width: '100%', height: 200, objectFit: 'contain'}}/> : null
            }
            <p style={{fontWeight: '600'}}>Câu&nbsp;{index + 1}.&nbsp;{dataQues?.question_name ?? ''}</p>
            <Radio.Group options={dataAnswer} onChange={onChange} value={value} style={{marginTop: 8, marginBottom: 8}}/>
        </div>
    )
}
export default ItemSurvey;