import { RightCircleOutlined } from "@ant-design/icons"
import { Card, Col, Row, TimePicker } from "antd"
import dayjs from "dayjs"
import React from "react"
import { DATE_FORMAT } from "../../../../constants/constants"

const ItemCard = ({index, startTime, endTime, date, handleOnchangeTime, onDelete}) => {

    const handleOnchange = (type, index, value) => {
        handleOnchangeTime(type, index, value);
    }
    return (
        <Col span={8}>
            <Card
                title={`Ca ${index + 1} - ${dayjs(date).format(DATE_FORMAT.DDMMYYYY_FORMAT)}`}
                bordered={false}
                style={{
                    marginTop: 8,
                    marginBottom: 8
                }}
                headStyle={{
                    backgroundColor: '#AFD198',
                    color: "#000",
                    height: 30,
                    fontWeight: '600',
                    fontSize: 18
                }}
                extra={<a style={{color: 'red', textDecorationLine: 'underline'}} href="#" onClick={() => onDelete(index)}>Xoá</a>}
            >
                <Row gutter={16} align="middle">
                    <Col span={10}>
                        <TimePicker allowClear={false} format={"HH:mm"} value={dayjs(startTime, 'HH:mm')} onChange={(value, dateString) => handleOnchange("START", index, dateString)}/>
                    </Col>
                    <Col span={4} style={{ textAlign: 'center' }}>
                        <RightCircleOutlined />
                    </Col>
                    <Col span={10}>
                        <TimePicker allowClear={false} format={"HH:mm"} value={dayjs(endTime, 'HH:mm')} onChange={(value, dateString) => handleOnchange("END", index, dateString)}/>
                    </Col>
                </Row>
                    
            </Card>
        </Col>


    )
}
export default React.memo(ItemCard)