import { Button, Col, Form, Input, Row, Space, theme, Table, message } from 'antd';
import React, { useEffect, useState } from 'react';
import LoadingBase from '../../../components/LoadingBase';
import '../../../css/listbook-page.css';
import AddDataModal from './component/AddDataModal';
import { addNewSurveyCategory, getSurveyCategory, updateSurveyCategory } from '../../../services/apis';
import { TYPE_MODAL } from '../../../constants/constants';

const SurveyTypePage = () => {
    const { token } = theme.useToken();
    const [form] = Form.useForm();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [titleModal, setTitleModal] = useState('');
    const [itemSelected, setItemSelected] = useState();
    const [typeModal, setTypeModal] = useState();
    const columns = [
        {
            title: 'Mã',
            dataIndex: 'id',
            key: 'id',
            width: 150,
            align: 'center',
        },
        {
            title: 'Tên',
            dataIndex: 'name',
            key: 'id',
            render: (text) => <div style={{padding: 8}}>{text}</div>
        },
        {
            title: 'Hành động',
            key: 'action',
            width: 80,
            align: 'center',
            render: (text, record) => <a onClick={() => handleEdit(record)} style={{}}>Sửa</a>
        },
        
    ];
    
    const formStyle = {
        maxWidth: 'none',
        background: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        padding: 24,
    };
    

    const handleEdit = (item) => {
        setTypeModal(TYPE_MODAL.EDIT);
        setShowModal(true);
        setTitleModal("Sửa thông tin loại khảo sát");
        setItemSelected(item);
    }
    const getDataSurveyType = async () => {
        const params = {
            name: form.getFieldValue("name") ?? '',
        }
        setLoading(true);
        const res = await getSurveyCategory(params);
        setLoading(false);
        if (res && res.status === 1) {
            setData([...res?.data]);
            message.success("Lấy dữ liệu thành công");
        } else {
            setData([]);
            message.error(res?.msg ?? "Lấy dữ liệu thất bại");
        }
    }

    const handleAddOrEditData = async (data) => {
        setShowModal(false);
        setLoading(true);
        if (typeModal === TYPE_MODAL.ADD) {
            const params = {
                name: data?.name,
            };
            
            const res = await addNewSurveyCategory(params);
            setLoading(false);
            if (res && res?.status === 1) {
                message.success("Thêm mới loại khảo sát thành công");
                getDataSurveyType();
            } else {
                message.error(res?.msg ?? "Thêm mới thất bại!")
            }
        } else {
            const params = {
                name: data?.name,
                id: data?.id
            };
            
            const res = await updateSurveyCategory(params);
            setLoading(false);
            if (res && res?.status === 1) {
                message.success("Cập nhật thành công");
                getDataSurveyType();
            } else {
                message.error(res?.msg ?? "Cập nhật thất bại!")
            }
        }
    }
    const handleCancel = () => {
        setShowModal(false);
    }

    const onFinish = async (data) => {
        getDataSurveyType();
    };

    useEffect(() => {
        getDataSurveyType();
    }, [])

    return (
        <div className='container-content'>
            <Form form={form} name="advanced_search" style={formStyle} onFinish={onFinish}>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            name={'name'}
                        >
                            <Input placeholder={'Tên loại bài khảo sát'} />
                        </Form.Item>
                    </Col>
                    
                    
                    <Col span={4}>
                        <Space size="small">
                            <Button type="primary" htmlType="submit">
                                Tìm kiếm
                            </Button>
                            <Button
                                onClick={() => {
                                    setShowModal(true);
                                    setTitleModal("Thêm mới loại khảo sát");
                                    setTypeModal(TYPE_MODAL.ADD)
                                }}
                            >
                                Thêm mới
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
            <Table
                className='table-book'
                columns={columns}
                dataSource={data}
                pagination={false}
                rowKey={(record) => record?.id}
            />
            
            <AddDataModal visible={showModal} onCancel={handleCancel} onSave={handleAddOrEditData} title={titleModal} type={typeModal} data={itemSelected}/>
            <LoadingBase loading={loading} />
        </div>
    )
}
export default React.memo(SurveyTypePage);