import { Button, Col, Form, Row, Space, theme, Table, message, DatePicker, Image } from 'antd';
import React, { useEffect, useState } from 'react';
import LoadingBase from '../../components/LoadingBase';
import '../../css/listbook-page.css';
import { getListCounselingResultByDate } from '../../services/apis';
import { consoleLogUtil } from '../../utils/consoleLogUtil';
import dayjs from 'dayjs';
import { BASE_URL } from '../../settings/config';
import { Images } from '../../assets';

const ListConsultationPage = () => {
    const { token } = theme.useToken();
    const [form] = Form.useForm();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const columns = [
        {
            title: 'STT',
            key: 'index',
            width: 50,
            align: 'center',
            render: (text, record, index) => index + 1
        },
        {
            title: 'Avatar',
            dataIndex: 'patient_avatar',
            key: 'patient_avatar',
            width: 70,
            align: 'center',
            render: (text) => <Image src={text ? (BASE_URL + text) : Images.ic_student} style={{width: 40, height: 40, borderRadius: 20, marginTop: 5, marginBottom: 5}}/>
        },
        {
            title: 'Họ tên',
            dataIndex: 'patient_fullname',
            key: 'patient_fullname',
            render: (text) => <div style={{paddingLeft: 16}}>{text}</div>
        },
        {
            title: 'SĐT',
            dataIndex: 'patient_phone',
            key: 'patient_phone',
            render: (text) => <div style={{paddingLeft: 16}}>{text}</div>
        },
        {
            title: 'Ngày tư vấn',
            dataIndex: 'shift_date',
            render: (text) => <div style={{paddingLeft: 16}}>{text ? dayjs(text).format("DD/MM/YYYY") : ''}</div>
        },
        {
            title: 'Khung giờ',
            render: (text, record) => <div style={{paddingLeft: 16}}>{`${record?.start_time} - ${record?.end_time}`}</div>
        },
        {
            title: 'Chuyên gia',
            dataIndex: 'doctor_fullname',
            render: (text) => <div style={{paddingLeft: 16}}>{text}</div>
        },
        {
            title: 'Hành động',
            render: (text, record) => <div style={{paddingLeft: 16}}><a href={`tu-van/${record?.id}`} target='_blank'>Xem</a></div>
        },
    ];
    
    const formStyle = {
        maxWidth: 'none',
        background: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        padding: 24,
    };
    
    const getDataCounseling = async (date) => {
        const params = {
            date: date
        }
        setLoading(true);
        const res = await getListCounselingResultByDate(params);
        setLoading(false);
        if (res && res.status === 1) {
            setData([...res?.data]);
        } else {
            setData([]);
            message.error(res?.msg ?? "Lấy dữ liệu thất bại");
        }
    }


    const onFinish = async (data) => {
        consoleLogUtil("dataaa", data);
        getDataCounseling(data?.date.format("YYYY-MM-DD"));
    };

    useEffect(() => {
        getDataCounseling(dayjs().format("YYYY-MM-DD"));
    }, [])

    return (
        <div className='container-content'>
            <Form form={form} name="advanced_search" style={formStyle} onFinish={onFinish}>
                <Row gutter={24}>
                    <Col span={6}>
                        <Form.Item
                            name={'date'}
                        >
                            <DatePicker  format={"DD/MM/YYYY"} defaultValue={dayjs()} style={{width: '100%', height: 40}}/>
                        </Form.Item>
                    </Col>
                    
                    
                    <Col span={4}>
                        <Space size="small">
                            <Button type="primary" htmlType="submit">
                                Tìm kiếm
                            </Button>
                            
                        </Space>
                    </Col>
                </Row>
            </Form>
            <Table
                className='table-book'
                columns={columns}
                dataSource={data}
                pagination={{
                    pageSize: 50
                }}
                rowKey={(record) => record?.id}
            />
            <LoadingBase loading={loading} />
        </div>
    )
}
export default React.memo(ListConsultationPage);