import { useEffect, useState } from "react";

import {
    Card,
    Col,
    Row,
    Typography,
    Tooltip,
    Button
} from "antd";
import {
    MenuUnfoldOutlined,
    MoreOutlined,
    RiseOutlined,
    FallOutlined,
    ClockCircleOutlined,
} from "@ant-design/icons";
import Paragraph from "antd/lib/typography/Paragraph";
import Echart from "../../components/chart/EChart";
import LoadingBase from "../../components/LoadingBase";
import { formatNumber } from "../../utils/funcHelper";
import { Images } from "../../assets";
import moment from "moment";
import { DATE_FORMAT } from "../../constants/constants";
import { useSelector } from "react-redux";
import { getCountDataShift, getCountSurveyComplete, getDataForChartDaily, getListConsultCompleteRecent, getListRegisterScheduleRecent, getListSurveyCompleteRecent } from "../../services/apis";
import { consoleLogUtil } from "../../utils/consoleLogUtil";
import { BASE_URL } from "../../settings/config";
import dayjs from "dayjs";

const DATA_DEFAULT = [
    {
        today: 0,
        title: "TỔNG SỐ CA",
        persent: 0,
        icon: Images.ic_shift,
        bnb: "bnb2",
        classBox: 'icon-box',
        toolTip: 'Số ca làm việc trong ngày'
    },
    {
        today: 0,
        title: "LƯỢT ĐĂNG KÝ",
        persent: 0,
        icon: Images.ic_register,
        bnb: "bnb2",
        classBox: 'icon-box',
        toolTip: 'Số lượt học sinh đăng ký tư vấn trong ngày'
    },
    {
        today: 0,
        title: "HOÀN THÀNH",
        persent: 0,
        icon: Images.ic_complete,
        bnb: "bnb2",
        classBox: 'icon-box',
        toolTip: 'Tổng số lượt hoàn thành tư vấn trong ngày'
    },
    {
        today: 0,
        title: "LÀM BÀI KHẢO SÁT",
        persent: 0,
        icon: Images.ic_surveyor,
        bnb: "redtext",
        classBox: 'icon-box',
        toolTip: 'Tổng số lượt làm bài khảo sát trong ngày'
    },

];

const lstSurveyDemo = [
    {
        name: "Nguyễn Đình Long",
        school: "THCS Nguyễn Văn Trỗi",
        class: "6A1",
        shift: "09:00 - 10:30",
        date: "27/05/2024 10:30:00"
    },
    {
        name: "Lê Uyên Nhi",
        school: "THCS Nguyễn Văn Trỗi",
        class: "6A1",
        shift: "10:30 - 11:30",
        date: "27/05/2024 11:30:00"
    },
    {
        name: "Đào Thuý Ngọc",
        school: "THCS Nguyễn Văn Trỗi",
        class: "6A1",
        shift: "13:30 - 14:30",
        date: "27/05/2024 14:30:00"
    },
];
const DashboardPage = () => {
    const userReducer = useSelector(state => state?.userReducer);
    const { Title, Text } = Typography;
    const [reverse, setReverse] = useState(false);
    const [loading, setLoading] = useState(false);
    const [dataTop, setDataTop] = useState(DATA_DEFAULT);
    const [dataChart, setDataChart] = useState([]);
    const [lstComplete, setLstComplete] = useState([]);
    const [lstRegister, setLstRegister] = useState([]);
    const [lstSurveyRecent, setLstSurveyRecent] = useState([]);

    const caculatorPersent = (numb1, numb2) => {
        let result = 0;
        if (numb2 > 0) {
            result = (numb1 - numb2)*100/numb2;
        } else {
            if (numb1 > 0) {
                result = 100
            } else {
                result = 0
            }
        }
        return result;
    }
    const getDataSurveyRecent = async () => {
        const res = await getListSurveyCompleteRecent();
        consoleLogUtil("getListSurveyCompleteRecent", res);
        if (res && res?.status === 1) {
            setLstSurveyRecent([...res?.data]);
        } else {
            setLstSurveyRecent([]);
        }
    }
    const getDataRegisRecent = async () => {
        const res = await getListRegisterScheduleRecent();
        if (res && res?.status === 1) {
            setLstRegister([...res?.data]);
        } else {
            setLstRegister([]);
        }
    }
    const getDataCompleteRecent = async () => {
        const res = await getListConsultCompleteRecent();
        if (res && res?.status === 1) {
            setLstComplete([...res?.data]);
        } else {
            setLstComplete([]);
        }
    }
    const getDataForChart = async () => {
        const res = await getDataForChartDaily();
        if (res && res?.status === 1) {
            setDataChart([...res?.data?.sort((a, b) => new Date(a.date) - new Date(b.date))]);
        } else {
            setDataChart([]);
        }
    }
    const getDataSurveyComplete = async () => {
        const res = await getCountSurveyComplete();
        if (res && res?.status === 1) {
            const temp = [...dataTop];
            const obj = res?.data;
            temp[3].today = obj?.current_participant_count;
            temp[3].persent = caculatorPersent(parseInt(obj?.current_participant_count), obj?.yesterday_participant_count)
        }
    }
    const getDataShift = async () => {
        const res = await getCountDataShift();
        if (res && res?.status === 1) {
            const temp = [...dataTop];
            const obj = res?.data;
            temp[0].today = obj?.shift_count;
            temp[0].persent = caculatorPersent(parseInt(obj?.shift_count), parseInt(obj?.shift_count_yesterday));
            temp[1].today = obj?.shift_registration_count;
            temp[1].persent = caculatorPersent(parseInt(obj?.shift_registration_count), parseInt(obj?.shift_registration_count_yesterday));
            temp[2].today = obj?.shift_registration_status4_count;
            temp[2].persent = caculatorPersent(parseInt(obj?.shift_registration_status4_count), parseInt(obj?.shift_registration_status4_count_yesterday));
            setDataTop([...temp]);
        }
    }

    useEffect(() => {
        setLoading(true);
        Promise.all([getDataShift(), getDataSurveyComplete(), getDataForChart(), getDataCompleteRecent(), getDataRegisRecent(), getDataSurveyRecent()])
            .catch(err => consoleLogUtil("err", err))
            .finally(() => setLoading(false))
    }, [])

    return (
        <>
            <div className="layout-content">
                <Row className="rowgap-vbox" gutter={[24, 0]}>
                    {dataTop.map((c, index) => (
                        <Col
                            key={index}
                            xs={24}
                            sm={24}
                            md={12}
                            lg={6}
                            xl={6}
                            className="mb-24"
                        >
                            <Card bordered={false} className="criclebox ">
                                <div className="number">
                                    <Row align="middle" gutter={[24, 0]}>
                                        <Col xs={18}>
                                            <span>{c.title}</span>

                                            <Tooltip placement="top" title={c.toolTip}>
                                                <Title level={3}>
                                                    {c.today} {index < 2 ? <small className="txt-unit">{index === 0 ? "Ca" : "Lượt"}</small> : null}
                                                </Title>
                                            </Tooltip>


                                        </Col>
                                        <Col xs={6} style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                            <Tooltip placement="top" title={'Tăng/giảm so với hôm qua'}>
                                                <div className="box-icon-chart">
                                                    {c.persent > 0 ? <RiseOutlined style={{ color: '#52c41a' }} /> : (c.persent < 0 ? <FallOutlined style={{ color: 'red' }} /> : null)}
                                                    <small className={c.bnb}>{`${formatNumber(c.persent, 0)}%`}</small>
                                                </div>

                                            </Tooltip>
                                            <div className={c.classBox}><img src={c.icon} className="img-db" /></div>
                                        </Col>
                                    </Row>
                                </div>
                            </Card>
                        </Col>
                    ))}
                </Row>

                <Row gutter={[24, 0]}>
                    <Col xs={24} sm={12} md={212} lg={12} xl={12} className="mb-24">
                        <Card bordered={false} className="criclebox h-full">
                            <Echart options={{
                                xaxis: {
                                    categories: dataChart?.map(item => moment(item.date, DATE_FORMAT.YYYYMMDD_FORMAT).format(DATE_FORMAT.DDMM_FORMAT))
                                }
                            }} series={[
                                {
                                    name: "Số lượt đăng ký",
                                    data: dataChart?.map(item => item.total),
                                    color: "#fff",
                                },
                            ]} />
                        </Card>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12} className="mb-24">
                        <Card bordered={false} className="criclebox h-full">
                            <div className="project-ant">
                                <div>
                                    <Title level={5}>Danh sách hoàn thành tư vấn gần đây</Title>
                                </div>

                            </div>
                            <div className="ant-list-box table-responsive">
                                <table className="width-100">
                                    <thead>
                                        <tr>
                                            <th>Họ tên</th>
                                            <th>Khung giờ</th>
                                            <th>Thời gian hoàn thành</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {lstComplete.map((d, index) => (
                                            <tr key={index}>
                                                <td>
                                                <div style={{ justifyItems: 'center', alignItems: 'center', display: 'flex', flexDirection: 'row' }}>
                                                        <img className="tootip-img" src={d?.patientAvatar?.length > 0 ? (BASE_URL + d?.patientAvatar) : Images.img_user} alt="" />
                                                        <a><p style={{ fontSize: 13, fontWeight: 'bold', paddingLeft: 12 }}>{d?.patientFullname ?? ''}</p></a>
                                                    </div>
                                                </td>
                                                <td>
                                                    <span className="text-xs font-weight-bold">
                                                        {d?.start_time} - {d?.end_time}
                                                    </span>
                                                </td>
                                                <td>
                                                    <span className="text-xs font-weight-bold">
                                                        {d?.createtime ? dayjs(d?.createtime).format("DD/MM/YYYY HH:mm:ss") : ''}
                                                    </span>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </Card>

                    </Col>
                </Row>

                <Row gutter={[24, 0]}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={16} className="mb-24">
                        <Card bordered={false} className="criclebox cardbody h-full">
                            <div className="project-ant">
                                <div>
                                    <Title level={5}>Danh sách đăng ký tư vấn gần đây</Title>
                                    <Paragraph className="lastweek">
                                        Số đăng ký hôm nay:<span className="blue" style={{ fontSize: 18 }}>{lstRegister.length}</span>
                                    </Paragraph>
                                </div>

                            </div>
                            <div className="ant-list-box table-responsive">
                                <table className="width-100">
                                    <thead>
                                        <tr>
                                            <th>Họ tên</th>
                                            <th>Trường</th>
                                            <th>Lớp</th>
                                            <th>Khung giờ</th>
                                            <th>Ngày</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {lstRegister.map((d, index) => (
                                            <tr key={index}>
                                                <td>
                                                    <div style={{ justifyItems: 'center', alignItems: 'center', display: 'flex', flexDirection: 'row' }}>
                                                        <img className="tootip-img" src={d?.avatar?.length > 0 ? (BASE_URL + d?.avatar) : Images.img_user} alt="" />
                                                        <a><p style={{ fontSize: 13, fontWeight: 'bold', paddingLeft: 12 }}>{d?.fullname ?? ''}</p></a>
                                                    </div>
                                                </td>
                                                <td>{d?.schoolname ?? ''}</td>
                                                <td>
                                                    <span className="text-xs font-weight-bold">
                                                        {d.classname ?? ''}
                                                    </span>
                                                </td>
                                                <td>
                                                    <span className="text-xs font-weight-bold">
                                                        {d?.start_time ?? ''} - {d?.end_time ?? ''}
                                                    </span>
                                                </td>
                                                <td>
                                                    <span className="text-xs fon-eight-bold">
                                                        {d?.shift_date ? dayjs(d?.shift_date).format("DD/MM/YYYY") : ''}
                                                    </span>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className="uploadfile shadow-none">
                                <Button
                                    type="dashed"
                                    className="ant-full-box"
                                    icon={<MoreOutlined />}
                                >
                                    <span className="click">Xem thêm</span>
                                </Button>
                            </div>
                        </Card>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={8} className="mb-24">
                        <Card bordered={false} className="criclebox h-full">
                            <div className="timeline-box">
                                <Title level={5}>Làm khảo sát mới nhất</Title>
                                <Paragraph className="lastweek" style={{ marginBottom: 24 }}>
                                </Paragraph>

                                {lstSurveyRecent.map((item, index) => (
                                    <Row style={{ marginBottom: 12 }}>
                                        <Col span={4} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <img src={item?.avatar?.length > 0 ? (BASE_URL + item?.avatar) : Images.img_user} style={{ width: 40, height: 40, borderRadius: 20 }} />
                                        </Col>
                                        <Col span={20}>
                                            <div style={{ flexDirection: 'column', display: 'flex' }}>
                                                <Title level={5}>{`${item.fullname ?? ''}`}</Title>
                                                <p>{item?.survey_name ?? ''}</p>
                                                <p style={{fontSize: 12, marginTop: 4}}><ClockCircleOutlined /> {item?.createtime ?? ''}</p>
                                            </div>
                                        </Col>
                                    </Row>

                                ))}
                                <Button
                                    type="primary"
                                    className="width-100 btn-more"
                                    onClick={() => setReverse(!reverse)}
                                >
                                    {<MenuUnfoldOutlined />} Xem thêm
                                </Button>
                            </div>
                        </Card>
                    </Col>
                </Row>
                <LoadingBase loading={loading} />
            </div>
        </>
    );
}

export default DashboardPage;
