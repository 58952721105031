import { Button, Col, Form, Input, Row, Space, theme, Table, message, DatePicker, Modal, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import LoadingBase from '../../components/LoadingBase';
import '../../css/listbook-page.css';
import { deleteShiftById, getListShift, getListUser, registerSchedule, updateScheduleById, updateShiftById } from '../../services/apis';
import { DATE_FORMAT, TYPE_MODAL } from '../../constants/constants';
import { useSelector } from 'react-redux';
import { consoleLogUtil } from '../../utils/consoleLogUtil'
import ItemCard from './component/ItemCard';
import SearchInput from '../../components/SearchInput';
import { requiredForm } from '../../utils/requiredform';
import dayjs from 'dayjs';
import EmptyData from '../../components/EmptyData';
import { filterOption } from '../../utils/funcHelper';

const ListShiftPage = () => {
    const { token } = theme.useToken();
    const userReducer = useSelector(state => state?.userReducer);
    const [form] = Form.useForm();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [lstDoctor, setLstDoctor] = useState([]);
    const [lstStudent, setLstStudent] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [titleModal, setTitleModal] = useState('');
    const [indexSelected, setIndexSelected] = useState();
    const [typeModal, setTypeModal] = useState();
    const [studentSelected, setStudentSelected] = useState();
    const columns = [
        {
            title: 'Mã',
            dataIndex: 'id',
            key: 'id',
            width: 80,
            align: 'center',
        },
        {
            title: 'Chuyên gia',
            dataIndex: 'doctor_fullname',
            key: 'id',
            render: (text) => <div style={{ padding: 8 }}>{text}</div>
        },
        {
            title: 'Ngày',
            dataIndex: 'shift_date',
            key: 'shift_date',
            render: (text) => <div style={{ padding: 8 }}>{text}</div>
        },
        {
            title: 'Khung giờ',
            render: (text, record) => <div style={{ padding: 8 }}>{`${record?.start_time} - ${record?.end_time}`}</div>
        },
        {
            title: 'Học sinh đăng ký',
            dataIndex: 'registration_fullname',
            key: 'registration_fullname',
            render: (text, record) => <div style={{ padding: 8 }}>{text}</div>
        },
        {
            title: 'Ngày tạo',
            dataIndex: 'createtime',
            key: 'createtime',
            render: (text, record) => <div style={{ padding: 8 }}>{text}</div>
        },
        {
            title: 'Hành động',
            key: 'action',
            width: 80,
            align: 'center',
            render: (text, record) => <a onClick={() => handleEdit(record)}>Sửa</a>
        },

    ];

    const formStyle = {
        maxWidth: 'none',
        background: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        padding: 24,
    };

    const handleEdit = (item) => {

        setShowModal(true);
        setTitleModal("Sửa thông tin lớp học");
    }
    const getDataShift = async (shift_date, user_id) => {
        setLoading(true);
        const params = {
            user_id: user_id,
            shift_date: shift_date ?? dayjs().format(DATE_FORMAT.YYYYMMDD_FORMAT)
        }
        const res = await getListShift(params);
        setLoading(false);
        if (res && res.status === 1) {
            consoleLogUtil("resss data", res)
            setData([...res?.data]);
            message.success("Lấy dữ liệu thành công");
        } else {
            setData([]);
            message.warning(res?.msg ?? "Lấy dữ liệu thất bại");
        }
    }

    const handleOnDeleteShift = async (index) => {
        const temp = data[index];
        if (temp?.registration_status) {
            message.error("Ca này đã tồn tại lịch đăng ký không thể xoá!");
        } else {
            setLoading(true);
            const params = {
                id: temp?.id
            };
            const res = await deleteShiftById(params);
            setLoading(false);
            if (res && res?.status === 1) {
                message.success("Xoá ca làm việc thành công!");
                getDataShift(dayjs(form.getFieldValue("date")).format(DATE_FORMAT.YYYYMMDD_FORMAT), form.getFieldValue("doctor"));
            } else {
                message.error(res?.msg ?? "Xoá ca thất bại. Vui lòng thử lại")
            }
        }
    }

    const handleSave = async (start, end, index) => {
        const temp = data[index];
        const params = {
            id: temp?.id,
            userId: temp?.user_id,
            shift_date: temp?.shift_date,
            start_time: start,
            end_time: end,
            create_by: userReducer?.data?.info?.id
        };
        setLoading(true);
        const res = await updateShiftById(params);
        setLoading(false);
        if (res && res?.status === 1) {
            message.success("Cập nhật dữ liệu thành công");
            getDataShift(dayjs(form.getFieldValue("date")).format(DATE_FORMAT.YYYYMMDD_FORMAT), form.getFieldValue("doctor"));
        } else {
            message.error(res?.msg ?? "Cập nhật dữ liệu thất bại. Vui lòng thử lại.")
        }
    }
    const getDataDoctors = async () => {
        const params = {
            keyword: '',
            type: 2
        }
        const res = await getListUser(params);
        if (res && res?.status === 1) {
            setLstDoctor([...res?.data?.map(item => ({
                id: item.id,
                name: item.fullname
            }))]);
        } else {
            setLstDoctor([]);
        }
    }

    const getDataStudents = async () => {
        const params = {
            keyword: '',
            type: 1
        }
        const res = await getListUser(params);
        if (res && res?.status === 1) {
            setLstStudent([...res?.data?.map(item => ({
                id: item.id,
                name: item.fullname
            }))]);
        } else {
            setLstStudent([]);
        }
    }

    const onFinish = async (data) => {
        getDataShift(data.date?.format(DATE_FORMAT.YYYYMMDD_FORMAT), data.doctor);
    };

    const onDeleteStudent = (index) => {
        setIndexSelected(index);
        setTypeModal(TYPE_MODAL.DEL);
        setShowModal(true);
    }
    const onAddStudent = (index) => {
        setIndexSelected(index);
        setTypeModal(TYPE_MODAL.ADD);
        setStudentSelected();
        setShowModal(true);
    }
    const onApprove = (index) => {
        setIndexSelected(index);
        setTypeModal(TYPE_MODAL.APPROVE);
        setShowModal(true);
    }
    const renderTitleModal = (type) => {
        let title = '';
        switch (type) {
            case TYPE_MODAL.DEL:
                title = "Xác nhận";
                break;
            case TYPE_MODAL.ADD:
                title = "Thêm học sinh";
                break;
            case TYPE_MODAL.APPROVE:
                title = "Duyệt lịch hẹn";
                break;

        }
        return title;
    }
    const callAPIUpdateSchedule = async (newStatus, messageSuccess, messageFailed) => {
        const temp = data[indexSelected];
        const params = {
            id: temp.regis_id,
            shift_id: temp.id,
            register_user_id: temp.register_user_id,
            status: newStatus
        }
        setLoading(true);
        const res = await updateScheduleById(params);
        setLoading(false);
        if (res && res?.status === 1) {
            message.success(messageSuccess);
            getDataShift(dayjs(form.getFieldValue("date")).format(DATE_FORMAT.YYYYMMDD_FORMAT), form.getFieldValue("doctor"));
        } else {
            message.error(res?.msg ?? messageFailed);
        }
    }
    const handleApproveSchedule = async () => {
        dissmiss();
        callAPIUpdateSchedule(3, "Duyệt lịch hẹn thành công", "Duyệt lịch hẹn thất bại");
    }
    const handleCancelSchedule = async () => {
        dissmiss();
        callAPIUpdateSchedule(2, "Xoá học sinh khỏi ca thành công", "Xoá học sinh thất bại");
    }

    const handleRegisterSchedule = async () => {
        dissmiss();
        const temp = data[indexSelected];
        const params = {
            shift_id: temp.id,
            register_user_id: studentSelected,
            status: 1
        }
        setLoading(true);
        const res = await registerSchedule(params);
        setLoading(false);
        if (res && res?.status === 1) {
            message.success("Thêm học sinh vào ca thành công");
            getDataShift(dayjs(form.getFieldValue("date")).format(DATE_FORMAT.YYYYMMDD_FORMAT), form.getFieldValue("doctor"));
        } else {
            message.error(res?.msg ?? "Thêm học sinh thất bại");
        }
    }

    const handleConfrim = () => {
        switch (typeModal) {
            case TYPE_MODAL.ADD:
                if (!studentSelected) {
                    return;
                }
                handleRegisterSchedule();
                break;

            case TYPE_MODAL.DEL:
                handleCancelSchedule();
                break;
            case TYPE_MODAL.APPROVE:
                handleApproveSchedule();
                break;    
        }
    }
    const handleSelectStudent = (id) => {
        // consoleLogUtil("handleSelectStudent", id);
        // const temp = lstStudent.find(st => st.id === id);
        // consoleLogUtil("temp", temp);
        setStudentSelected(id);
    }
    const renderContentModal = (type) => {
        if (type === TYPE_MODAL.DEL) {
            return (
                <p>Bạn có chắc chắn muốn xoá học sinh khỏi ca tư vấn này?</p>
            )
        }

        if (type === TYPE_MODAL.ADD) {
            return (
                <>
                    <p style={{ paddingBottom: 8 }}>Chọn học sinh vào ca tư vấn: {data[indexSelected]?.start_time} - {data[indexSelected]?.end_time}</p>
                    <Select
                        showSearch
                        placeholder={"Chọn học sinh"}
                        style={{ height: 40 }}
                        defaultActiveFirstOption={false}
                        filterOption={filterOption}
                        notFoundContent={null}
                        onSelect={handleSelectStudent}
                        options={(lstStudent || []).map((d) => ({
                            value: d.id,
                            label: d.name,
                        }))}
                    />
                </>
            )
        }
        if (type === TYPE_MODAL.APPROVE) {
            return (
                <p>{`Bạn có chắc chắn đồng ý duyệt lịch hẹn với ${data[indexSelected]?.registration_fullname} không?`}</p>
            )
        }

    }

    const dissmiss = () => {
        setShowModal(false);
    }
    useEffect(() => {
        setLoading(true);
        Promise.all([getDataDoctors(), getDataStudents(), getDataShift()])
            .then(res => { })
            .catch(err => {
                consoleLogUtil("errr", err)
            })
            .finally(() => setLoading(false));
    }, [])

    return (
        <div className='container-content'>
            <Form form={form} name="advanced_search" style={formStyle} onFinish={onFinish}>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            name="date"
                        >
                            <DatePicker
                                format={"DD-MM-YYYY"}
                                className="input-number-base"
                                placeholder='Chọn ngày'
                                defaultValue={dayjs()}
                                allowClear={false}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name="doctor"
                            rules={[
                                {
                                    required: true,
                                    message: 'Vui lòng chọn chuyên gia'
                                }
                            ]}
                        >
                            <Select
                                showSearch
                                placeholder={"Chọn chuyên gia"}
                                style={{ height: 40 }}
                                defaultActiveFirstOption={false}
                                filterOption={filterOption}
                                notFoundContent={null}
                                options={(lstDoctor || []).map((d) => ({
                                    value: d.id,
                                    label: d.name,
                                }))}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={4}>
                        <Space size="small">
                            <Button type="primary" htmlType="submit">
                                Tìm kiếm
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
            <Row gutter={24} style={{ backgroundColor: '#fafafa', padding: 16, borderRadius: 8, marginLeft: 0, marginRight: 0, marginTop: 8 }}>
                {
                    data.length > 0 ? data.map((item, index) => (
                        <ItemCard
                            index={index}
                            startTime={item.start_time}
                            endTime={item.end_time}
                            date={item.shift_date}
                            onDelete={handleOnDeleteShift}
                            handleSave={handleSave}
                            fullname={item?.doctor_fullname}
                            status={item.registration_status}
                            regName={item?.registration_fullname}
                            onDeleteStudent={onDeleteStudent}
                            onAddStudent={onAddStudent}
                            onApprove={onApprove}
                            key={item.id}
                        />
                    )) : <EmptyData />
                }
            </Row>
            <Modal
                open={showModal}
                onOk={handleConfrim}
                onCancel={dissmiss}
                okText={"Xác nhận"}
                cancelText="Thoát"
                title={renderTitleModal(typeModal)}
            >
                {
                    renderContentModal(typeModal)
                }
            </Modal>
            <LoadingBase loading={loading} />
        </div>
    )
}
export default React.memo(ListShiftPage);